import React, { useEffect, useRef, useState } from 'react'
import PageHeader from '../../../components/PageHeader'
import styles from './style.module.scss'
import { MessageIcon } from '../../../assets/icons/message-icon';
import Button from '../../../components/Button';
import { ChatIcon } from '../../../assets/icons/new-chat-icon';
import { SendIcon } from '../../../assets/icons/send-icon';
import { EmojiIcon } from '../../../assets/icons/emoji-icon';
function AIChat() {
  const lastMessageRef = useRef(null);

  const [chatHistory, setChatHistory] = useState([
    {
      id: Math.random(),
      text: "JavaScript undefined Concept",
      messages: [
        {
          type: "user",
          text: "reandom message from user",
        },
        {
          type: "chatbot",
          text: "reandom message  response from chatbot"
        },
        {
          type: "user",
          text: "reandom message from user",
        },
        {
          type: "chatbot",
          text: "reandom message  response from chatbot"
        },
      ]
    },
    {
      id: Math.random(),
      text: "Undefined Concepts in JavaScript",
      messages: [
        {
          type: "user",
          text: "reandom message from user",
        },
        {
          type: "chatbot",
          text: "reandom message  response from chatbot"
        },
        {
          type: "user",
          text: "reandom message from user",
        },
        {
          type: "chatbot",
          text: "reandom message  response from chatbot"
        },
      ]
    },
    {
      id: Math.random(),
      text: "Tech Term: Me+",
      messages: [
        {
          type: "user",
          text: "reandom message from user",
        },
        {
          type: "chatbot",
          text: "reandom message  response from chatbot"
        },
        {
          type: "user",
          text: "reandom message from user",
        },
        {
          type: "chatbot",
          text: "reandom message  response from chatbot"
        },
      ]
    },
    {
      id: Math.random(),
      text: "Vuetify Multiple Selection Guide",
      messages: [
        {
          type: "user",
          text: "reandom message from user",
        },
        {
          type: "chatbot",
          text: "reandom message  response from chatbot"
        },
        {
          type: "user",
          text: "reandom message from user",
        },
        {
          type: "chatbot",
          text: "reandom message  response from chatbot"
        },
      ]
    },
    {
      id: Math.random(),
      text: "Me+ Explanation Needed",
      messages: [
        {
          type: "user",
          text: "reandom message from user",
        },
        {
          type: "chatbot",
          text: "reandom message  response from chatbot"
        },
        {
          type: "user",
          text: "reandom message from user",
        },
        {
          type: "chatbot",
          text: "reandom message  response from chatbot"
        },
      ]
    },
  ]);
  const [newChat, setNewChat] = useState(true);
  const [activeHistory, setActiveHistory] = useState(0);
  const [resLoader, setResLoader] = useState(false);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const btnStyle = {
    color: "#0E1726",
    fontFamily: "Nunito",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    letterSpacing: "0.05em",
    borderRadius: "4px",
    boxShadow: 'none',
    padding: '4px',
    backgroundColor: 'transparent',
    border: 'none',
    color: 'black',
  };


  const homeScreenCards = [
    {
      title: "Most Popular Promts",
      subTitle: "SEO / Writing",
    },
    {
      title: "Most Popular Promts",
      subTitle: "SEO / Writing",
    },
    {
      title: "Most Popular Promts",
      subTitle: "SEO / Writing",
    },
    {
      title: "Most Popular Promts",
      subTitle: "SEO / Writing",
    }
  ]
  useEffect(() => {
    moveScreenToBottom();
  }, [activeHistory, resLoader]);
  const sendMessage = (message) => {
    setResLoader(true);
    const activeChat = chatHistory.find(
      (chat) => chat.conversationId === activeHistory
    );
    // if (activeChat) {
    //   // Update the chat history with the new message
    //   const updatedChatHistory = chatHistory.map((chat) => {
    //     if (chat.id === activeHistory) {
    //       return {
    //         ...chat,
    //       };
    //     }
    //     return chat;
    //   });

    //   // Set the updated chat history
    //   setChatHistory(updatedChatHistory);
    // }
    setMessages([
      ...messages,
      {
        type: "chatbot",
        text: message,
      },
    ]);
    setMessage("")
    setTimeout(() => setResLoader(false), 0);

  };
  function moveScreenToBottom() {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }
  const handleHistoryClick = (historyId) => {
    setNewChat(false);
    setActiveHistory(historyId);
    const historyMessages = chatHistory?.filter(
      (item) => item.id === historyId
    )[0];
    setMessages(historyMessages.messages);
  };

  const activeStyle = {
    borderBottom: "1px solid #EBEBEB",
    fontWeight: "600"
  }
  return (
    <div className={styles.root}>
      <PageHeader title="AI Bot" subHeading="Manage your team members and their account permissions here." hideButton={true} />
      <div className={styles.container}>
        <div className={styles.left_wrapper}>
          <div className={styles.items_container}>
            <Button text='New Chat' endIcon={
              <div className={styles.chat_circle}>
                <ChatIcon /></div>
            }
              styles={btnStyle}
              backgroundColor='transparent'
              border='none'
              color='black'
              shadow='none'
            />
            <div className={styles.chat_history}>
              {chatHistory?.map((item, index) =>
                <div className={styles.chats_side} onClick={() => handleHistoryClick(item.id)}
                  style={item.id === activeHistory ? activeStyle : {}}
                >
                  <MessageIcon />
                  <span>{item.text}</span>
                </div>)}
            </div>
          </div>
        </div>
        <div className={styles.chat_wrapper}>
          {newChat ?
            <div className={styles.new_chat_home_screen}>
              <div className={styles.top}>
                <div className={styles.brand_logo}>
                  <img
                    src="/images/logo.svg"
                    alt="company logo"
                    style={{ width: "32px" }}
                  />
                  <div className={styles.logo_text}>VRISTO</div>
                </div>
                <div style={{ display: 'flex', gap: "12px" }}>
                  <div className={styles.cards_container}>
                    {homeScreenCards?.slice(0, 2)?.map((items, index) => <div key={index} className={styles.card}>
                      <div className={styles.card_title}>{items.title}</div>
                      <div className={styles.card_sub_title}>{items.subTitle}</div>
                    </div>)}
                  </div>
                  <div className={styles.cards_container}>
                    {homeScreenCards?.slice(2, 4)?.map((items, index) => <div key={index} className={styles.card}>
                      <div className={styles.card_title}>{items.title}</div>
                      <div className={styles.card_sub_title}>{items.subTitle}</div>
                    </div>)}
                  </div>
                </div>
              </div>
              <div className={styles.message_input_container}>
                <input type="text" value={message} placeholder='Type a message' onChange={(e) => { setMessage(e.target.value) }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      sendMessage(message)
                    }
                  }}
                />
                <div onClick={() => sendMessage(message)}><SendIcon /></div>
              </div>
            </div>
            :
            <div className={styles.messages_home_screen}>
              <div className={styles.messages_list}>
                {messages.map((message, index) => (
                  message.type === 'chatbot' ? <div className={styles.chatbot_message_container}>
                    <div className={styles.avatar}>
                      <img src='/images/chatbot.png' />
                    </div>
                    <div className={styles.message_area}>
                      <div className={styles.message_text_and_emoji}>
                        <div className={styles.message_text}>{message.text}</div>
                        <EmojiIcon />
                      </div>
                      <div className={styles.message_time}>5h ago</div>

                    </div>
                  </div> : <div className={styles.row}>
                    <div className={styles.user_message_container}>
                      <div className={styles.avatar}>
                        <img src='/images/user1.png' />
                      </div>
                      <div className={styles.message_area}>
                        <div className={styles.message_text_and_emoji}>
                          <div className={styles.message_text}>{message.text}</div>
                          <EmojiIcon />
                        </div>
                        <div className={styles.message_time}>5h ago</div>
                      </div>
                    </div>
                  </div>
                ))}
                <div ref={lastMessageRef}></div>
              </div>
              <div className={styles.message_input_container}>
                <input type="text" value={message} placeholder='Type a message' onChange={(e) => { setMessage(e.target.value) }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      sendMessage(message)
                    }
                  }}
                />
                <div onClick={() => sendMessage(message)}><SendIcon /></div>
              </div>
            </div>}
        </div>
      </div>
    </div>
  )
}

export default AIChat