import React, { useState } from 'react'

function Button({ text = "", startIcon, endIcon, onClick = () => { }, styles, disabled = false, padding = '10px 16px', height = '100%', backgroundColor = '#1570EF', color = '#FFFFFF', shadow = "0px 10px 20px -10px rgba(67, 97, 238, 0.60)", border = `1px solid #1570EF`, borderRadius = "8px", }) {
    const [clicked, setClicked] = useState(false);
    const customStyles = {
        color: color,
        display: "flex",
        padding: padding,
        height: height,
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        borderRadius: borderRadius,
        border: border,
        backgroundColor: backgroundColor,
        background: clicked ? "#135CB3" : backgroundColor,
        cursor: disabled ? "not-allowed" : "pointer", // Change cursor for disabled state
        opacity: disabled ? 0.5 : 1, // Reduce opacity for disabled state
        pointerEvents: disabled ? "none" : "auto", // Disable pointer events for disabled state
        boxShadow: clicked ? "0px 2px 4px rgba(0, 0, 0, 0.2)" : shadow,
        cursor: "pointer",
        transition: "background-color 0.3s, box-shadow 0.3s",
        ...styles
    }


    const handleMouseDown = () => {
        setClicked(true);
    };

    const handleMouseUp = () => {
        setClicked(false);
    };
    return (
        <button
            style={customStyles}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            onClick={onClick}
            disabled={disabled}
        >
            {startIcon}
            {text}
            {endIcon}

        </button>
    )
}

export default Button