export const PasswordIcon=()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
  <path d="M1.5 12C1.5 9.8787 1.5 8.81805 2.15901 8.15903C2.81802 7.5 3.87868 7.5 6 7.5H12C14.1213 7.5 15.1819 7.5 15.841 8.15903C16.5 8.81805 16.5 9.8787 16.5 12C16.5 14.1213 16.5 15.1819 15.841 15.841C15.1819 16.5 14.1213 16.5 12 16.5H6C3.87868 16.5 2.81802 16.5 2.15901 15.841C1.5 15.1819 1.5 14.1213 1.5 12Z" stroke="#0E1726" stroke-width="1.125"/>
  <path opacity="0.5" d="M4.5 7.5V6C4.5 3.51472 6.51472 1.5 9 1.5C11.4853 1.5 13.5 3.51472 13.5 6V7.5" stroke="#0E1726" stroke-width="1.125" stroke-linecap="round"/>
  <g opacity="0.5">
    <path d="M6.75 12C6.75 12.4142 6.41421 12.75 6 12.75C5.58579 12.75 5.25 12.4142 5.25 12C5.25 11.5858 5.58579 11.25 6 11.25C6.41421 11.25 6.75 11.5858 6.75 12Z" fill="#0E1726"/>
    <path d="M9.75 12C9.75 12.4142 9.41422 12.75 9 12.75C8.58578 12.75 8.25 12.4142 8.25 12C8.25 11.5858 8.58578 11.25 9 11.25C9.41422 11.25 9.75 11.5858 9.75 12Z" fill="#0E1726"/>
    <path d="M12.75 12C12.75 12.4142 12.4142 12.75 12 12.75C11.5858 12.75 11.25 12.4142 11.25 12C11.25 11.5858 11.5858 11.25 12 11.25C12.4142 11.25 12.75 11.5858 12.75 12Z" fill="#0E1726"/>
  </g>
</svg>
    )
}