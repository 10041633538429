import React from 'react';
import styles from "./style.module.scss"
import PageHeader from '../../../components/PageHeader';

import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import keyIcon from "../../../assets/icons/svg/user.svg";
import mailIcon from "../../../assets/icons/svg/mail.svg";
import mobileIcon from "../../../assets/icons/svg/tablet.svg";
import addressIcon from "../../../assets/icons/svg/address.svg";
// import keyIcon from "../../../assets/icons/svg/key.svg";

import { useNavigate } from "react-router-dom";
import Button from '../../../components/Button';
import { Dropzone } from '../../../components/Dropzone';


const EditProfile = () => {

    const navigate = useNavigate();

    return (
        <div className={styles.container}>
            <PageHeader title="Edit Account" subHeading="Manage your team members and their account permissions here." hideButton={true} />

            <div className={styles.card}>
                <Formik
                    onSubmit={(values) => {
                        console.log(values);
                    }}
                >
                    {({ errors, touched }) => (
                        <Form style={{ width: "100%" }}>
                            <div className={styles.fields}>

                                <div className={styles.field_container}>
                                    <label>Profile Image</label>
                                    <Dropzone />

                                </div>
                                <div className={styles.hr}>
                                </div>

                                <div className={styles.field_container}>
                                    <label>First Name</label>
                                    <div>
                                        <div style={{ display: "flex" }}>
                                            <img className={styles.icon} src={keyIcon} alt="icon" />
                                            <Field
                                                className={styles.field}
                                                name="firstName"
                                                placeholder="First Name"
                                                type={"text"}
                                            />

                                        </div>
                                    </div>


                                </div>
                                <div className={styles.hr}>
                                </div>


                                <div className={styles.field_container}>
                                    <label>Last Name</label>
                                    <div>
                                        <div style={{ display: "flex" }}>
                                            <img className={styles.icon} src={keyIcon} alt="icon" />
                                            <Field
                                                className={styles.field}
                                                name="lastName"
                                                placeholder="Last Name"
                                                type={"text"}
                                            />

                                        </div>
                                    </div>


                                </div>
                                <div className={styles.hr}>
                                </div>

                                <div className={styles.field_container}>
                                    <label>Email</label>
                                    <div>
                                        <div style={{ display: "flex" }}>
                                            <img className={styles.icon} src={mailIcon} alt="icon" />
                                            <Field
                                                className={styles.field}
                                                name="email"
                                                placeholder="Email"
                                                type={"email"}
                                                disabled={true}
                                            />

                                        </div>
                                    </div>


                                </div>
                                <div className={styles.hr}>
                                </div>

                                <div className={styles.field_container}>
                                    <label>Mobile No</label>
                                    <div>
                                        <div style={{ display: "flex" }}>
                                            <img className={styles.icon} src={mobileIcon} alt="icon" />
                                            <Field
                                                className={styles.field}
                                                name="mobileNo"
                                                placeholder="Mobile Number"
                                                type={"text"}
                                            />

                                        </div>
                                    </div>


                                </div>
                                <div className={styles.hr}>
                                </div>

                                <div className={styles.field_container}>
                                    <label>Address</label>
                                    <div>
                                        <div style={{ display: "flex" }}>
                                            <img className={styles.icon} src={addressIcon} alt="icon" />
                                            <Field
                                                className={styles.field}
                                                name="address"
                                                placeholder="Address"
                                                type={"text"}
                                            />

                                        </div>
                                    </div>


                                </div>
                                <div className={styles.hr}>
                                </div>
                                <div className={styles.btns}>
                                    <Button text='Cancel' shadow='none' color='#1570EF' backgroundColor='#ffffff' />
                                    <Button text='Save' color='#ffffff' />
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default EditProfile;