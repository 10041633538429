import React, { useEffect, useState } from 'react';
import styles from './table.module.scss';
import { SortIcon } from '../../assets/icons/sort-icon';
import { selectDateFilterOptionValue, selectDateFilterState, selectSearchText, selectSortFilterState } from '../../redux/slice/ui/ui-slice';
import { useSelector } from 'react-redux';
import { usDateFormat } from '../../utils/usDateFormat';

function Table({ data = [], columns }) {
    const [tableData, setTableData] = useState(data)
    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const sortFilterSelector = useSelector(selectSortFilterState)
    const dateFilterSelecter = useSelector(selectDateFilterState)
    const selectDateFilterValues = useSelector(selectDateFilterOptionValue)
    const searchText = useSelector(selectSearchText)
    useEffect(() => {
        if (data)
            setTableData(data)
    }, [data])
    const handleSort = (columnName) => {
        if (sortBy === columnName) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(columnName);
            setSortOrder('asc');
        }
        applyColumnSort();
    };
    const applyColumnSort = () => {
        const sortedData = [...data].sort((a, b) => {
            const aValue = a[sortBy];
            const bValue = b[sortBy];
            if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });
        setTableData(sortedData)
    }



    useEffect(() => {

        if (searchText && searchText?.trim() !== '') {
            const filteredData = data?.filter(item => item?.campaignName?.toLowerCase()?.includes(searchText?.toLowerCase()));
            setTableData(filteredData)
        }
        if (sortFilterSelector) {
            if (sortFilterSelector === '') return data;
            const sortedData = data?.slice()?.sort(sortByValue(sortFilterSelector));
            setTableData(sortedData)
        }
    }, [searchText, sortFilterSelector])

    useEffect(() => {
        if (dateFilterSelecter) {
            const filterDate = new Date(dateFilterSelecter)
            const filteredData = data?.filter(item => {
                const itemDate = new Date(item.createdAt);
                return itemDate.getTime() === filterDate.getTime();
            });
            setTableData(filteredData)
        }
        if (selectDateFilterValues) {
            const res = filterDataByDate(data, selectDateFilterValues)
            setTableData(res)
        }
    }, [dateFilterSelecter, selectDateFilterValues])

    useEffect(() => {

    }, [searchText])

    function filterDataByDate(data, optionValue) {
        const currentDate = new Date();
        const startDate = new Date();

        switch (optionValue) {
            case 7:
                startDate.setDate(currentDate.getDate() - 7);
                break;
            case 30:
                startDate.setMonth(currentDate.getMonth() - 1);
                break;
            case 90:
                startDate.setMonth(currentDate.getMonth() - 3);
                break;
            case 356:
                startDate.setFullYear(currentDate.getFullYear() - 1);
                break;

            default:
                return data;
        }
        return data.filter(item => {
            return new Date(item.createdAt) >= startDate
        });
    }

    function sortByValue(optionValue) {
        switch (optionValue) {
            case 'cost':
                return (a, b) => a.cost - b.cost;
            case 'leads':
                return (a, b) => a.leads - b.leads;
            case 'cost per lead':
                return (a, b) => a.costPerLead - b.costPerLead;
            case 'date':
                return (a, b) => new Date(a.createdAt) - new Date(b.createdAt);
            default:
                return () => 0; // No sorting
        }
    }
    return (
        <div className={styles.container}>
            <table>
                <thead>
                    <tr>
                        {columns?.map((column, index) => (
                            // to exlude the createdAt column name from columns
                            column.name != 'createdAt' && <th key={index} onClick={() => handleSort(column.name)}>
                                {/* {column.text} */}
                                <div> {column.text} {<span style={{ transform: sortBy === column.name && sortOrder === 'asc' ? 'rotate(180deg)' : "" }}>{sortOrder === 'asc' ? <SortIcon /> : <SortIcon />}</span>}</div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tableData?.map((row, index) => (
                        <tr key={index}>
                            {columns.map((column, columnIndex) => (
                                // to exlude the createdAt column data from rows
                                column.name != 'createdAt' && <td key={columnIndex}>{row[column.name]}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default Table;
