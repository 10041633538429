import React, { useState } from 'react'
import styles from './style.module.scss'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
function SelectFilter({ style = {}, options = [], value = "", onChange = () => { }, transparent = false, setOpenDatePicker }) {
    const [customDate, setCustomDate] = useState(null);
    const customStyle = {
        ...style
    }
    console.log("style:", style)
    return (
        <div className={styles.select_container} style={customStyle}>
            <FormControl fullWidth>
                <Select
                    labelId="select-label"
                    id="select"
                    value={value || ''}
                    onChange={onChange}
                    displayEmpty
                    MenuProps={styles.options}
                    sx={{
                        boxShadow: "none",
                        ".MuiOutlinedInput-notchedOutline": {
                            border: transparent ? 0 : '1px solid var(--input-borderColor, #D1D5DB)'
                        },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                            border: transparent ? 0 : '',
                            outline: "none"
                        },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                            border: transparent ? 0 : '',
                        },
                    }}
                >

                    {options?.map(item => (
                        item.value === "custom_date" ?
                            <MenuItem value={item.value} onClick={() => setOpenDatePicker(true)}>
                                {customDate || item.label}
                            </MenuItem>
                            :
                            <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
}

export default SelectFilter