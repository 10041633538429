import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/PageHeader";
import TableWrapper from "../../../components/TableWrapper";
import Pagination from "../../../components/Tables/Pagination";
import { extractPageData } from "../../../utils/extractPageData";
import Table from "../../../components/Tables";
import { crm_hql_data } from "../../../assets/data/crm_hql_data";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
function AllLeads() {
  const [tableData, setTableData] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [numberOfRecPerPage,setNumberOfRecordsPerPage] = useState(10);
  const [selectedColumns, setSelectedColumns] = useState([])
  const paginate = (pageNumber) => setCurrentPageNumber(pageNumber);
  const columnsText = [
    "Date",
    "Email Address",
    "Utm Source",
    "Utm Medium",
    "Utm Campaign",
    "Utm Ad Set Id",
    "Utm Ad Id",
    "Glid"
  ]
  useEffect(() => {
    // Get current list/page data
    if (crm_hql_data) {
      const data = extractPageData(
        crm_hql_data,
        currentPageNumber,
        numberOfRecPerPage
      );
      setCurrentPageData(data);
    }
  }, [currentPageNumber, crm_hql_data,numberOfRecPerPage]);
  useEffect(() => {
    setTableData(crm_hql_data);
  }, []);
  const windowsDimensions = useWindowDimensions()
  return (
    <div>
      <PageHeader subHeading="Manage your team members and their account permissions here." />
      <TableWrapper
      columns={columnsText}
        setSelection={setSelectedColumns}
      >
        <Table
          data={currentPageData}
          columns={Object.keys(crm_hql_data?.[0]).map((key, index) => ({
            name: key,
            text: selectedColumns[index] //columnsText[index]
          }))}
        />
        <Pagination
          totalData={tableData.length}
          dataPerPage={numberOfRecPerPage}
          setNumberOfRecordsPerPage={setNumberOfRecordsPerPage}

          paginate={paginate}
          currentPage={currentPageNumber}
        />
      </TableWrapper>
    </div>
  );
}

export default AllLeads;
