import { usDateFormat } from "../../utils/usDateFormat";

function generateRandomDate() {
    const day = Math.floor(Math.random() * 28) + 1; // Random day between 1 and 28
    const month = Math.floor(Math.random() * 12) + 1; // Random month between 1 and 12
    const year = Math.floor(Math.random() * (2023 - 2000 + 1)) + 2000; // Random year between 2000 and 2023
    return `${month}/${day}/${year}`;
}
export const compaignsData = Array.from({ length: 23 }, (_, index) => ({
    campaignName: `Campaign ${index + 1} Name`,
    campaignId: Math.floor(Math.random() * 100000), // Random campaign ID
    cost: parseFloat((Math.random() * 1000).toFixed(2)), // Random cost between 0 and 1000
    clicks: Math.floor(Math.random() * 1000), // Random clicks between 0 and 1000
    averageCpc: parseFloat((Math.random() * 5).toFixed(2)), // Random average CPC between 0 and 5
    ctr: parseFloat((Math.random() * 10).toFixed(2)), // Random CTR between 0 and 10
    leads: Math.floor(Math.random() * 100), // Random leads between 0 and 100
    hql: Math.floor(Math.random() * 50), // Random HQL between 0 and 50
    costPerLead: parseFloat((Math.random() * 20).toFixed(2)), // Random cost per lead between 0 and 20
    costPerHql: parseFloat((Math.random() * 50).toFixed(2)), // Random cost per HQL between 0 and 50
    optinRate: parseFloat((Math.random() * 100).toFixed(2)), // Random opt-in rate between 0 and 100
    roi: parseFloat((Math.random() * 500).toFixed(2)), // Random ROI between 0 and 500
    callBooked: Math.floor(Math.random() * 20), // Random calls booked between 0 and 20
    revenue: parseFloat((Math.random() * 5000).toFixed(2)), // Random revenue between 0 and 5000
    basicProductSales: Math.floor(Math.random() * 50), // Random basic product sales between 0 and 50
    basicProductSalesPercentage: parseFloat((Math.random() * 100).toFixed(2)), // Random basic product sales percentage between 0 and 100
    costPerSale: parseFloat((Math.random() * 50).toFixed(2)), // Random cost per sale between 0 and 50
    costPerCallBooked: parseFloat((Math.random() * 100).toFixed(2)), // Random cost per call booked between 0 and 100
    createdAt: usDateFormat(generateRandomDate()),// Random date in the format dd/mm/yyyy
}));    
