// Function to generate a random date string in the format dd/mm/yyyy
function generateRandomDate() {
    const day = Math.floor(Math.random() * 28) + 1; // Random day between 1 and 28
    const month = Math.floor(Math.random() * 12) + 1; // Random month between 1 and 12
    const year = Math.floor(Math.random() * (2023 - 2000 + 1)) + 2000; // Random year between 2000 and 2023
    return `${month}/${day}/${year}`;
}

// Function to generate a random email
function generateRandomEmail() {
    const domain = ["gmail.com", "yahoo.com", "hotmail.com"];
    const usernameLength = Math.floor(Math.random() * (10 - 5 + 1)) + 5; // Random username length between 5 and 10
    let username = "";
    for (let i = 0; i < usernameLength; i++) {
        const randomCharCode = Math.floor(Math.random() * (122 - 97 + 1)) + 97; // Random lowercase ASCII code
        username += String.fromCharCode(randomCharCode);
    }
    const selectedDomain = domain[Math.floor(Math.random() * domain.length)];
    return `${username}@${selectedDomain}`;
}

// Function to generate a random name
function generateRandomName() {
    const names = ["Jad", "Marcel", "Nabila", "Sofia", "Amir", "Lina", "Karim", "Layla"];
    return names[Math.floor(Math.random() * names.length)];
}

// Generate random data based on the provided structure
export const crm_all_data = Array.from({ length: 15 }, (_, index) => ({
    email: generateRandomEmail(), // Random email address
    date: generateRandomDate(), // Random date in the format dd/mm/yyyy
    utmSource: "GoogleAds",
    utmMedium: "PMAX",
    utmCampaign: Math.random().toString().slice(2, 20), // Random 18-digit number
    utmAdSetId: generateRandomDate(), // Random date for Utm_Ad Set ID
    utmAdId: "#REF!", // Assuming #REF! indicates an error, kept as is
    campaignName: "-",  // Assuming missing data for campaignName
    regDate: "null",  // Assuming #REF! indicates an error, replaced with null
    createdAt: generateRandomDate(), // Random date in the format dd/mm/yyyy

}));

