import * as React from "react";
import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import style from "./registeration-form.module.scss";

import userIcon from "../../../assets/icons/svg/user.svg";
import AdIcon from "../../../assets/icons/svg/ad-icon.svg";
import mailIcon from "../../../assets/icons/svg/mail.svg";
import keyIcon from "../../../assets/icons/svg/key.svg";
import eyeIcon from "../../../assets/icons/svg/eye.svg";
import eyeOffIcon from "../../../assets/icons/svg/eye-off.svg";
import googleIcon from "../../../assets/icons/svg/google_img.svg";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { mobile_breakpoint } from "../../../utils/constants";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

export default function RegistrationForm() {
  const [showPassword, setShowPassword] = useState(false);
  const isMobile = useMediaQuery(mobile_breakpoint);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1 }} className={style.container}>
      <Grid container>
        <Grid item xs={12}>
          <div className={style.head}>
            <img src={AdIcon} alt="icon" />
            <div className={style.text}>Google Ads</div>
          </div>
          <div className={style.content}>
            <div className={style.text}>Sign Up</div>
            <div className={style.description}>
              Let’s get started and give you a brand new experience
            </div>

            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                confirmPassword: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                console.log(values);
              }}
            >
              {({ errors, touched }) => (
                <Form>
                  <div className={style.first_name} style={{ width: "100%" }} >
                    <div style={{ display: "flex", width: "100%", gap: 10, flexWrap: isMobile ? "wrap" : "nowrap" }}>
                      <div style={{ width: "100%" }}>
                        <div style={{ display: "flex", width: "100%", }}>
                          <img
                            className={style.icon}
                            src={userIcon}
                            alt="icon"
                          />
                          <Field
                            style={{ width: "100%" }}
                            className={`${style.field} ${errors.firstName && touched.firstName
                              ? style.error
                              : ""
                              }`}
                            name="firstName"
                            placeholder="First Name"
                            type="text"
                          />
                        </div>
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className={style.errorText}
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <div style={{ display: "flex" }}>
                          <img
                            className={style.icon}
                            src={userIcon}
                            alt="icon"
                          />
                          <Field
                            style={{ width: "100%" }}

                            className={`${style.field} ${errors.lastName && touched.lastName
                              ? style.error
                              : ""
                              }`}
                            name="lastName"
                            placeholder="Last Name"
                            type="text"
                          />
                        </div>
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className={style.errorText}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={style.fields}>
                    <div>
                      <div style={{ display: "flex" }}>
                        <img className={style.icon} src={mailIcon} alt="icon" />
                        <Field
                          className={`${style.field} ${errors.email && touched.email ? style.error : ""
                            }`}
                          name="email"
                          placeholder="Email"
                          type="email"
                        />
                      </div>
                      <ErrorMessage
                        name="email"
                        component="div"
                        className={style.errorText}
                      />
                    </div>
                    <div>
                      <div style={{ display: "flex" }}>
                        <img className={style.icon} src={keyIcon} alt="icon" />
                        <Field
                          className={`${style.field} ${errors.password && touched.password
                            ? style.error
                            : ""
                            }`}
                          name="password"
                          placeholder="Password"
                          type={showPassword ? "text" : "password"}
                        />
                        <img
                          src={showPassword ? eyeOffIcon : eyeIcon}
                          alt="toggle"
                          className={style.eyeIcon}
                          onClick={togglePasswordVisibility}
                        />
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className={style.errorText}
                      />
                    </div>
                    <div>
                      <div style={{ display: "flex" }}>
                        <img className={style.icon} src={keyIcon} alt="icon" />
                        <Field
                          className={`${style.field} ${errors.confirmPassword && touched.confirmPassword
                            ? style.error
                            : ""
                            }`}
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          type={showConfirmPassword ? "text" : "password"}
                        />
                        <img
                          src={showConfirmPassword ? eyeOffIcon : eyeIcon}
                          alt="toggle"
                          className={style.eyeIcon}
                          onClick={toggleConfirmPasswordVisibility}
                        />
                      </div>
                      <ErrorMessage
                        name="confirmPassword"
                        component="div"
                        className={style.errorText}
                      />
                    </div>
                  </div>
                  <div className={style.remember_me}>
                    <div className={style.remember}>
                      <Field
                        type="checkbox"
                        name="terms"
                        className={style.checkBox}
                      />
                      <div className={style.text}>
                        I Agree to terms & conditions
                      </div>
                      <ErrorMessage
                        name="terms"
                        component="div"
                        className={style.errorText}
                      />
                    </div>
                  </div>
                  <button type="submit" className={style.signUpBtn}>
                    Sign Up
                  </button>
                  <div className={style.google_btn}>
                    <img
                      className={style.google_icon}
                      src={googleIcon}
                      alt="google-icon"
                    />
                    <div className={style.text}>Sign Up with Google</div>
                  </div>
                  <div className={style.create_account}>
                    <div className={style.sign_up}>
                      Already have an account?
                    </div>
                    <div
                      className={style.text}
                      onClick={() => navigate("/")}
                    >
                      Sign In
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}
