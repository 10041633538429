import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./TopBar.module.scss";
import ProfileIcon from "../../../assets/icons/profile-icon";
import { PasswordIcon } from "../../../assets/icons/password-icon";
import { SignoutIcon } from "../../../assets/icons/signout-icon";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectIsTopMenuBarOpen, setIsTopMenuBarOpen } from "../../../redux/slice/ui/ui-slice";
import Hamburger from "hamburger-react";

const items = [
  {
    title: "Profile",
    icon: ProfileIcon,
    url: "/dashboard/edit-profile",
  },
  {
    title: "Change Password",
    icon: PasswordIcon,
    url: "/dashboard/change-password",
  },
];


export default function TopBar({ userName, user }) {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const windowsDimensions = useWindowDimensions()
  const isSideBarOpen = useSelector(selectIsTopMenuBarOpen)

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  useEffect(() => {
    if (collapsed && !isDropdownOpen) {
      return;
    }

    window.addEventListener('click',
      function (event) {
        event.stopImmediatePropagation();
        event.stopPropagation();
        event.preventDefault();

        if (!collapsed && event.target.id != "profileMenu") {
          setCollapsed(true)
        }
      })

  }, [collapsed, isDropdownOpen])

  const handleSignOut = () => {

    navigate("/")
    setCollapsed(true)
    setDropdownOpen(false)
  }
  const mobileStyle = {
    position: "fixed",
    width: "100%",
    padding: '0px 20px',
    boxSizing: 'border-box',
    zIndex: 10,
  }
  return (
    <div className={`${styles.container}`} style={windowsDimensions.width <= 600 ? mobileStyle : { boxSizing: "border-box" }}>
      {<div className={styles.left}>
        {windowsDimensions.width <= 600 && <Hamburger toggled={isSideBarOpen} size={20} onToggle={(toggle) =>
          dispatch(setIsTopMenuBarOpen(!isSideBarOpen))
        } />}
      </div>}
      <div className={styles.right}>
        <div className={styles.profile_container}>
          <img src="images/user.png" alt="profile"
            id="profileMenu"
            onClick={() => {
              setCollapsed(!collapsed)
              setDropdownOpen(false)
            }}
          />
          <div className={styles.profile_menu_container}>
            <div
              className={`${collapsed ? styles.collapsed : styles.profile_menubar}`}
              id="profile_menubar"
            >
              <div className={styles.top} onClick={() => {
                setCollapsed(!collapsed)
                setDropdownOpen(false)
                navigate("/dashboard/profile")

              }}>
                <img src="images/user.png" alt="profile-pic" className={styles.pic_container}></img>
                <div className={styles.details_container}>
                  <span className={styles.name_section}>
                    <span className={styles.name}>John Doe</span>
                    <span className={styles.tag}>Pro</span>
                  </span>
                  <span className={styles.email}>Email@email.com</span>
                </div>
              </div>
              <div className={styles.middle}>
                {
                  items.map((item, index) => {
                    return (
                      <Link to={item.url} className={styles.link} onClick={() => {
                        setCollapsed(true)
                        setDropdownOpen(false)
                      }}>
                        <div className={styles.menu_item}>
                          <item.icon />
                          <p>{item.title}</p>

                        </div>
                      </Link>
                    )
                  })
                }
              </div>
              <div className={styles.horizontal_row}></div>
              <div className={styles.bottom} onClick={handleSignOut}>
                <div className={styles.menu_item}>
                  <SignoutIcon />
                  <p>Sign Out</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}
