export const CoinsHandIcon = ({ color = "#009688" }) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <g clip-path="url(#clip0_236_35254)">
            <path d="M9.75256 6.19418C9.34369 6.48567 8.84333 6.65713 8.30293 6.65713C6.92221 6.65713 5.80293 5.53785 5.80293 4.15713C5.80293 2.77642 6.92221 1.65714 8.30293 1.65714C9.19792 1.65714 9.98306 2.12743 10.4247 2.83438M4.37435 14.5765H6.23885C6.48195 14.5765 6.72356 14.6055 6.95922 14.6633L8.92928 15.142C9.35674 15.2462 9.80205 15.2563 10.234 15.1724L12.4122 14.7486C12.9876 14.6366 13.5169 14.361 13.9317 13.9575L15.4728 12.4584C15.913 12.031 15.913 11.3374 15.4728 10.9093C15.0766 10.5239 14.4492 10.4805 14.0009 10.8074L12.2048 12.1177C11.9476 12.3058 11.6346 12.407 11.3127 12.407H9.57828L10.6823 12.407C11.3045 12.407 11.8085 11.9167 11.8085 11.3114V11.0922C11.8085 10.5896 11.4569 10.1514 10.9558 10.0299L9.25192 9.61552C8.97463 9.54827 8.69064 9.51428 8.40517 9.51428C7.71602 9.51428 6.46856 10.0849 6.46856 10.0849L4.37435 10.9606M14.3744 4.87142C14.3744 6.25213 13.2551 7.37142 11.8744 7.37142C10.4936 7.37142 9.37435 6.25213 9.37435 4.87142C9.37435 3.49071 10.4936 2.37142 11.8744 2.37142C13.2551 2.37142 14.3744 3.49071 14.3744 4.87142ZM1.51721 10.6571L1.51721 14.8C1.51721 15.2 1.51721 15.4 1.59506 15.5528C1.66355 15.6872 1.77282 15.7965 1.90722 15.865C2.06001 15.9428 2.26003 15.9428 2.66007 15.9428H3.2315C3.63153 15.9428 3.83155 15.9428 3.98435 15.865C4.11875 15.7965 4.22802 15.6872 4.2965 15.5528C4.37435 15.4 4.37435 15.2 4.37435 14.8V10.6571C4.37435 10.2571 4.37435 10.0571 4.2965 9.90428C4.22802 9.76988 4.11875 9.66061 3.98435 9.59213C3.83155 9.51428 3.63154 9.51428 3.2315 9.51428L2.66007 9.51428C2.26003 9.51428 2.06001 9.51428 1.90722 9.59213C1.77282 9.66061 1.66355 9.76988 1.59506 9.90428C1.51721 10.0571 1.51721 10.2571 1.51721 10.6571Z"
                stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_236_35254">
                <rect width="17.1429" height="17.1429" fill="white" transform="translate(0.088623 0.228561)" />
            </clipPath>
        </defs>
    </svg>
}