import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/PageHeader";
import TableWrapper from "../../../components/TableWrapper";
import { tempData } from "../../../assets/data/tempData";
import Pagination from "../../../components/Tables/Pagination";
import { extractPageData } from "../../../utils/extractPageData";
import Table from "../../../components/Tables";
import { bestAdData } from "../../../assets/data/bestAdData";
import { getTableWidth } from "../../../utils/getTableWidth";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
function BestAds() {
  const [tableData, setTableData] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [numberOfRecPerPage, setNumberOfRecordsPerPage] = useState(10);
  const [selectedColumns, setSelectedColumns] = useState([])

  const paginate = (pageNumber) => setCurrentPageNumber(pageNumber);
  const columnsText = ["Ad Name", "Ad ID", "Cost", "Average CPC", "CTR", "Clicks", "Leads", "Cost per Lead", "Optin Rate", "Calls Booked", "Calls Booked %", "Cost Per Call", "PSU Sales", "Cost Per Sale", "Revenue", "ROI"];
  useEffect(() => {
    // Get current list/page data
    if (bestAdData) {
      const data = extractPageData(
        bestAdData,
        currentPageNumber,
        numberOfRecPerPage
      );
      setCurrentPageData(data);
    }
  }, [currentPageNumber, numberOfRecPerPage, bestAdData]);
  useEffect(() => {
    setTableData(bestAdData);
  }, []);

  const windowsDimensions = useWindowDimensions()
  return (
    <div>
      <PageHeader subHeading="Manage your team members and their account permissions here." />
      <TableWrapper
        // maxWidth={getTableWidth(windowsDimensions?.width)}
        columns={columnsText}
        setSelection={setSelectedColumns}
      >
        <Table
          data={currentPageData}
          columns={Object.keys(bestAdData?.[0]).map((key, index) => ({
            name: key,
            text: selectedColumns[index] //columnsText[index]
          }))}
        />
        <Pagination
          totalData={tableData.length}
          dataPerPage={numberOfRecPerPage}
          paginate={paginate}
          setNumberOfRecordsPerPage={setNumberOfRecordsPerPage}

          currentPage={currentPageNumber}
        />
      </TableWrapper>
    </div>
  );
}

export default BestAds;
