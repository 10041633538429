import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SideSection from "../../../components/auth/side-section/side-section";
import LoginForm from "../../../components/auth/login/login-form";
import { useMediaQuery } from "@mui/material";
import { mobile_breakpoint } from "../../../utils/constants";

export default function Login() {
  const isMobile = useMediaQuery(mobile_breakpoint);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container>
        <Grid item xs={12} md={5}>
          <LoginForm />
        </Grid>
        {!isMobile && <Grid item xs={12} md={7}>
          <SideSection />
        </Grid>}
      </Grid>
    </Box>
  );
}
