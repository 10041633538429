import React, { useState } from 'react';
import styles from "./style.module.scss"
import FormControl from '@mui/material/FormControl';
import { useDispatch } from 'react-redux';
import { setSearchText } from '../../redux/slice/ui/ui-slice';
const Search = ({ style }) => {
    const dispatch = useDispatch();
    const [value, setValue] = useState("")
    const handleChange = (e) => {
        setValue(e.target.value)
        dispatch(setSearchText(e.target.value))
    }
    const customeStyle = {
        ...style
    }
    return (
        <FormControl style={customeStyle}>
            <input
                type="search"
                name='search' placeholder='Search by typing'
                value={value}
                onChange={handleChange}
                className={styles.search} />
        </FormControl>

    )
}

export default Search;