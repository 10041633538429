import { generateRandomDate } from "./bestAdData";

function getRandomDate(startDate, endDate) {
    return new Date(startDate.getTime() + Math.random() * (endDate.getTime() - startDate.getTime()));
}

export const dailyReportData = Array.from({ length: 1 }, (_, index) => {
    const startDate = new Date(2024, 0, 1); // Assuming the start date
    const endDate = new Date(); // Assuming today's date
    const randomDate = getRandomDate(startDate, endDate);
    
    return {
        date: `${randomDate.getDate()}-${randomDate.getMonth() + 1}-${randomDate.getFullYear()}`, // Format: dd-mm-yyyy
        cost: parseFloat((Math.random() * 1000).toFixed(2)), // Random cost between 0 and 1000
        leads: Math.floor(Math.random() * 10), // Random leads between 0 and 10
        costPerLead: parseFloat((Math.random() * 10).toFixed(2)), // Random cost per lead between 0 and 10
        hql: parseFloat((Math.random() * 10).toFixed(2)), // Random cost per lead between 0 and 10
        costPerHql: parseFloat((Math.random() * 10).toFixed(2)), // Random cost per lead between 0 and 10
        createdAt: generateRandomDate(), // Random date in the format dd/mm/yyyy
    
    };
});