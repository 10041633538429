import React from 'react';
import styles from "./style.module.scss"
import PageHeader from '../../../components/PageHeader';

import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import keyIcon from "../../../assets/icons/svg/key.svg";
import eyeIcon from "../../../assets/icons/svg/eye.svg";
import eyeOffIcon from "../../../assets/icons/svg/eye-off.svg";
import { useNavigate } from "react-router-dom";
import Button from '../../../components/Button';
import { useMediaQuery } from '@mui/material';
import { custome_breakpoint, mobile_breakpoint, tablet_breakpoint } from '../../../utils/constants';

const validationSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    newPassword: Yup.string().required("New Password is required"),
    confirmNewPassword: Yup.string().required("Confirm Password is required")
});

const ChangePassword = () => {

    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const isNearMobileScreen = useMediaQuery(custome_breakpoint);


    const navigate = useNavigate();

    return (
        <div className={styles.container}>
            <PageHeader title="Password" subHeading="Manage your team members and their account permissions here." hideButton={true} />

            <div className={styles.card}>
                <Formik
                    initialValues={{ password: "", newPassword: "", confirmPassword: "" }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        console.log(values);
                    }}
                >
                    {({ errors, touched }) => (
                        <Form style={{ width: '100%' }}>
                            <div className={styles.fields}>

                                <div className={styles.field_container}>
                                    <label>Current Password</label>
                                    <div style={{ width: isNearMobileScreen ? '100%' : "" }}>
                                        <div style={{ display: "flex" }}>
                                            <img className={styles.icon} src={keyIcon} alt="icon" />
                                            <Field
                                                className={`${styles.field} ${errors.password && touched.password ? styles.error : ""
                                                    }`}
                                                name="password"
                                                placeholder="Password"
                                                type={showPassword ? "text" : "password"}
                                            />
                                            <img
                                                src={showPassword ? eyeOffIcon : eyeIcon}
                                                alt="toggle"
                                                className={styles.eyeIcon}
                                                onClick={() => setShowPassword(prev => !prev)}
                                            />

                                        </div>
                                        <ErrorMessage
                                            name="password"
                                            component="div"
                                            className={styles.errorText}
                                        />
                                    </div>


                                </div>
                                <div className={styles.hr}>
                                </div>

                                <div className={styles.field_container}>
                                    <label>New Password</label>
                                    <div style={{ width: isNearMobileScreen ? '100%' : "" }}>
                                        <div style={{ display: "flex" }}>
                                            <img className={styles.icon} src={keyIcon} alt="icon" />
                                            <Field
                                                className={`${styles.field} ${errors.newPassword && touched.newPassword ? styles.error : ""
                                                    }`}
                                                name="newPassword"
                                                placeholder="New Password"
                                                type={showNewPassword ? "text" : "password"}
                                            />
                                            <img
                                                src={showNewPassword ? eyeOffIcon : eyeIcon}
                                                alt="toggle"
                                                className={styles.eyeIcon}
                                                onClick={() => setShowNewPassword(prev => !prev)}
                                            />

                                        </div>
                                        <ErrorMessage
                                            name="newPassword"
                                            component="div"
                                            className={styles.errorText}
                                        />
                                    </div>


                                </div>

                                <div className={styles.hr}>
                                </div>

                                <div className={styles.field_container}>
                                    <label>Confirm New Password</label>
                                    <div style={{ width: isNearMobileScreen ? '100%' : "" }}>
                                        <div style={{ display: "flex" }}>
                                            <img className={styles.icon} src={keyIcon} alt="icon" />
                                            <Field
                                                className={`${styles.field} ${errors.confirmNewPassword && touched.confirmNewPassword ? styles.error : ""
                                                    }`}
                                                name="confirmNewPassword"
                                                placeholder="Confirm New Password"
                                                type={showConfirmNewPassword ? "text" : "password"}
                                            />
                                            <img
                                                src={showConfirmNewPassword ? eyeOffIcon : eyeIcon}
                                                alt="toggle"
                                                className={styles.eyeIcon}
                                                onClick={() => setShowConfirmNewPassword(prev => !prev)}
                                            />

                                        </div>
                                        <ErrorMessage
                                            name="confirmNewPassword"
                                            component="div"
                                            className={styles.errorText}
                                        />
                                    </div>


                                </div>

                                <div className={styles.hr}>
                                </div>

                                <div className={styles.btns}>
                                    <Button text='Cancel' shadow='none' color='#1570EF' backgroundColor='#ffffff' />
                                    <Button text='Save' color='#ffffff' />
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default ChangePassword;