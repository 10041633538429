export const DollarShieldIcon = ({ color = '#F500F5' }) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <g clip-path="url(#clip0_236_35249)">
            <path d="M7.33926 10.5857C7.56227 11.0103 8.00755 11.3 8.52048 11.3H10.0443C10.8333 11.3 11.4729 10.6604 11.4729 9.87142C11.4729 9.08244 10.8333 8.44285 10.0443 8.44285H8.61572C7.82674 8.44285 7.18715 7.80325 7.18715 7.01427C7.18715 6.2253 7.82674 5.5857 8.61572 5.5857H10.1395C10.6525 5.5857 11.0977 5.87534 11.3208 6.29999M9.33001 4.51427V5.5857M9.33001 11.3V12.3714M15.0443 8.79999C15.0443 12.306 11.22 14.856 9.82857 15.6678C9.67044 15.76 9.59137 15.8061 9.47978 15.8301C9.39318 15.8486 9.26684 15.8486 9.18024 15.8301C9.06865 15.8061 8.98958 15.76 8.83144 15.6678C7.43998 14.856 3.61572 12.306 3.61572 8.79999V5.38399C3.61572 4.81291 3.61572 4.52737 3.70912 4.28192C3.79163 4.06509 3.92571 3.87162 4.09976 3.71822C4.29679 3.54459 4.56415 3.44433 5.09887 3.24381L8.92872 1.80761C9.07722 1.75193 9.15147 1.72408 9.22785 1.71305C9.29561 1.70326 9.36441 1.70326 9.43216 1.71305C9.50855 1.72408 9.5828 1.75193 9.73129 1.80761L13.5611 3.24381C14.0959 3.44433 14.3632 3.54459 14.5603 3.71822C14.7343 3.87162 14.8684 4.06509 14.9509 4.28192C15.0443 4.52737 15.0443 4.81291 15.0443 5.38399V8.79999Z"
                stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_236_35249">
                <rect width="17.1429" height="17.1429" fill="white" transform="translate(0.758545 0.228561)" />
            </clipPath>
        </defs>
    </svg>
}