export const tempData = Array.from({ length: 20 }, (_, index) => ({
    id: index + 1,
    date: randomDate(),
    cost: `$ ${((Math.random() * 10) + 1).toFixed(2)}`,
    leads: Math.floor(Math.random() * 10) + 1,
    costPerLead: `$ ${(((Math.random() * 5) + 0.5) / (((Math.random() * 10) + 1))).toFixed(2)}`,
}));

// Function to generate a random date
function randomDate() {
    const start = new Date(2022, 0, 1);
    const end = new Date();
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime())).toLocaleDateString();
}
