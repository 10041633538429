import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import style from "./side-section.module.scss";
import laptop from "../../../assets/images/evalufy-thumbnail_img.svg";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function SideSection() {

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box sx={{ flexGrow: 1, padding: sm ? "40px 0px 40px 40px" : "" }} className={style.container}>
      <Grid container>
        <Grid item xs={12}>
          <div className={style.heading}>
            <div className={style.title}>Welcome to Google Ads</div>
            <div className={style.subTitle}>
              Clarity gives you the blocks & components you need to create a
              truly professional website.
            </div>
          </div>
          <div className={style.laptop}>
            <img className={style.img} src={laptop} alt="laptop" />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}
