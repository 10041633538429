import React, { useState } from "react";
import styles from "./dashboard.module.scss";
import Select from "../../components/Select";
import { EyeIcon } from "../../assets/icons/eye-icon";
import { ThreeHorizentalDotsIcon } from "../../assets/icons/three-horizental-dots-icon";
import { IconButton, useMediaQuery } from "@mui/material";
import { DollarIcon } from "../../assets/icons/dollar-icon";
import { DollarShieldIcon } from "../../assets/icons/dollar-shield";
import { CoinsHandIcon } from "../../assets/icons/coins-hand-icon";
import { AdminProfitMultiLineChart } from "../../components/charts/AdminProfitMultiLineChart";
import { AdminGrowthMultiLineChart } from "../../components/charts/AdminGrowthMultiLineChart";
import AdminSmallLineChart from "../../components/charts/AdminSmallLineChart";
import { hexToRgbConverter } from "../../utils/hexToRgbConverter";
import DatePickerComponent from "../../components/DatePicker";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "../../components/Button/index"
import SectionHeader from "../../components/SectionHeader";
import ThotAI from "../../components/ThotAI";
import { custome_breakpoint } from "../../utils/constants";

const filterOptions = [
  { label: "Select Date", value: "" },
  { label: "Past week", value: 7 },
  { label: "This month", value: 30 },
  { label: "Past 3 months", value: 90 },
  { label: "Past 12 months", value: 356 },
  { label: "Custom date", value: "custom_date" },
  { label: "All time", value: 0 },
];

const funnel_stats1 = [
  {
    id: 0,
    title: "Ad Spend",
    amount: "$170.46",
    ratio: "+ 2.35%",
    lastWeekStats: "Last Week 32,700",
    cardBGStyle: {
      background: "linear-gradient(90deg, #DA48F0 0%, #E777F8 100%)",
    },
    chipBGStyle: {
      background: "rgba(255, 255, 255, 0.30)",
    },
  },
  {
    id: 1,
    title: "Landing Page Views",
    amount: "$170.46",
    ratio: "+ 2.35%",
    lastWeekStats: "Last Week 44,700",
    cardBGStyle: {
      background: "linear-gradient(90deg, #06B6D4 0%, #22D3EE 100%)",
    },
    chipBGStyle: {
      background: "rgba(255, 255, 255, 0.30)",
    },
  },
  {
    id: 2,
    title: "Leads",
    amount: "78,085",
    ratio: "+ 1.5%",
    lastWeekStats: "Last Week 34,700",
    cardBGStyle: {
      background: "linear-gradient(90deg, #8B5CF6 0%, #A78BFA 100%)",
    },
    chipBGStyle: {
      background: " rgba(255, 255, 255, 0.30)",
    },
  },
  {
    id: 3,
    title: "Calls Booked",
    amount: "38,085",
    ratio: "+ 1.35%",
    lastWeekStats: "Last Week 63,700",
    cardBGStyle: {
      background: "linear-gradient(90deg, #3B82F6 0%, #60A5FA 100%)",
    },
    chipBGStyle: {
      background: " rgba(255, 255, 255, 0.30)",
    },
  },
];

const funnel_stats2 = [
  {
    id: 0,
    title: "Calls Booked",
    amount: "$170.46",
    ratio: "+ 2.35%",
    lastWeekStats: "Last Week 32,700",
    cardBGStyle: {
      background: "linear-gradient(90deg, #3B82F6 0%, #60A5FA 100%)",
      // background: "linear-gradient(90deg, #DA48F0 0%, #E777F8 100%)",
    },
    chipBGStyle: {
      background: "rgba(255, 255, 255, 0.30)",
    },
  },
  {
    id: 1,
    title: "Cost Per Call Booked",
    amount: "$170.46",
    ratio: "+ 2.35%",
    lastWeekStats: "Last Week 44,700",
    cardBGStyle: {
      background: "linear-gradient(90deg, #DA48F0 0%, #E777F8 100%)",
    },
    chipBGStyle: {
      background: "rgba(255, 255, 255, 0.30)",
    },
  },
  {
    id: 2,
    title: "Booking Rate",
    amount: "78,085",
    ratio: "+ 1.5%",
    lastWeekStats: "Last Week 34,700",
    cardBGStyle: {
      background: "linear-gradient(90deg, #06B6D4 0%, #22D3EE 100%)",
      // background: "linear-gradient(90deg, #8B5CF6 0%, #A78BFA 100%)",
    },
    chipBGStyle: {
      background: " rgba(255, 255, 255, 0.30)",
    },
  },
  {
    id: 3,
    title: " Optin Rate",
    amount: "38,085",
    ratio: "+ 1.35%",
    lastWeekStats: "Last Week 63,700",
    cardBGStyle: {
      background: "linear-gradient(90deg, #8B5CF6 0%, #A78BFA 100%)",
      // background: "linear-gradient(90deg, #3B82F6 0%, #60A5FA 100%)",
    },
    chipBGStyle: {
      background: " rgba(255, 255, 255, 0.30)",
    },
  },
];
const funnel_stats3 = [
  {
    id: 0,
    title: "Total Unit Sales A",
    amount: "$170.46",
    ratio: "+ 2.35%",
    lastWeekStats: "Last Week 32,700",
    cardBGStyle: {
      background: "linear-gradient(90deg, #8B5CF6 0%, #A78BFA 100%)",
      // background: "linear-gradient(90deg, #DA48F0 0%, #E777F8 100%)",
    },
    chipBGStyle: {
      background: "rgba(255, 255, 255, 0.30)",
    },
  },
  {
    id: 1,
    title: "Total Sales A",
    amount: "$170.46",
    ratio: "+ 2.35%",
    lastWeekStats: "Last Week 44,700",
    cardBGStyle: {
      background: "linear-gradient(90deg, #3B82F6 0%, #60A5FA 100%)",
      // background: "linear-gradient(90deg, #06B6D4 0%, #22D3EE 100%)",
    },
    chipBGStyle: {
      background: "rgba(255, 255, 255, 0.30)",
    },
  },
  {
    id: 2,
    title: "Closing Rate A",
    amount: "78,085",
    ratio: "+ 1.5%",
    lastWeekStats: "Last Week 34,700",
    cardBGStyle: {
      background: "linear-gradient(90deg, #DA48F0 0%, #E777F8 100%)",
      // background: "linear-gradient(90deg, #8B5CF6 0%, #A78BFA 100%)",
    },
    chipBGStyle: {
      background: " rgba(255, 255, 255, 0.30)",
    },
  },
  {
    id: 3,
    title: "Short ROI",
    amount: "38,085",
    ratio: "+ 1.35%",
    lastWeekStats: "Last Week 63,700",
    cardBGStyle: {
      background: "linear-gradient(90deg, #06B6D4 0%, #22D3EE 100%)",
      // background: "linear-gradient(90deg, #3B82F6 0%, #60A5FA 100%)",
    },
    chipBGStyle: {
      background: " rgba(255, 255, 255, 0.30)",
    },
  },
];
const funnel_stats4 = [
  {
    id: 0,
    title: "Total Unite Sales B",
    amount: "$170.46",
    ratio: "+ 2.35%",
    lastWeekStats: "Last Week 32,700",
    cardBGStyle: {
      background: "linear-gradient(90deg, #06B6D4 0%, #22D3EE 100%)",
      // background: "linear-gradient(90deg, #DA48F0 0%, #E777F8 100%)",
    },
    chipBGStyle: {
      background: "rgba(255, 255, 255, 0.30)",
    },
  },
  {
    id: 1,
    title: "Total Sales B",
    amount: "$170.46",
    ratio: "+ 2.35%",
    lastWeekStats: "Last Week 44,700",
    cardBGStyle: {
      background: "linear-gradient(90deg, #8B5CF6 0%, #A78BFA 100%)",
      // background: "linear-gradient(90deg, #06B6D4 0%, #22D3EE 100%)",
    },
    chipBGStyle: {
      background: "rgba(255, 255, 255, 0.30)",
    },
  },
  {
    id: 2,
    title: "Closing Rate B",
    amount: "78,085",
    ratio: "+ 1.5%",
    lastWeekStats: "Last Week 34,700",
    cardBGStyle: {
      background: "linear-gradient(90deg, #3B82F6 0%, #60A5FA 100%)",

    },
    chipBGStyle: {
      background: " rgba(255, 255, 255, 0.30)",
    },
  },
  {
    id: 3,
    title: "Long ROI",
    amount: "38,085",
    ratio: "+ 1.35%",
    lastWeekStats: "Last Week 63,700",
    cardBGStyle: {
      background: "linear-gradient(90deg, #DA48F0 0%, #E777F8 100%)",
      // background: "linear-gradient(90deg, #3B82F6 0%, #60A5FA 100%)",
    },
    chipBGStyle: {
      background: " rgba(255, 255, 255, 0.30)",
    },
  },
];
const threeChartsData = [
  {
    title: "Total Unit Sales A",
    subTitle: "Sub info   ",
    amount: "$20,000",
    ratio: "+0.25%",
    icon: <DollarIcon color="#1570EF" />,
    color: "#1570EF",
  },
  {
    title: "Total Sales",
    subTitle: "Sub info",
    amount: "$21,000",
    ratio: "-1.25%",
    icon: <DollarShieldIcon color="#F500F5" />,
    color: "#F500F5",
  },
  {
    title: "Short ROI %",
    subTitle: "Sub info   ",
    amount: "24.23%",
    ratio: "+0.25%",
    icon: <CoinsHandIcon color="#009688" />,
    color: "#009688",
  },
  {
    title: "Total Unit Sales A",
    subTitle: "Sub info   ",
    amount: "$20,000",
    ratio: "+0.25%",
    icon: <DollarIcon color="#E7515A" />,
    color: "#E7515A",
  },
  {
    title: "Total Sales",
    subTitle: "Sub info   ",
    amount: "$21,000",
    ratio: "-1.25%",
    icon: <DollarShieldIcon color="#1570EF" />,
    color: "#1570EF",
  },
  {
    title: "Short ROI %",
    subTitle: "Sub info   ",
    amount: "24.23%",
    ratio: "+0.25%",
    icon: <CoinsHandIcon color="#E2A03F" />,
    color: "#E2A03F",
  },
];
function Dashboard() {
  const [filter, setFilter] = useState(filterOptions[0].value);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const isNearMobileScreen = useMediaQuery(custome_breakpoint);

  const handleFilter = (event) => {
    const value = event.target.value;
    if (value === "custom_date") {
      setOpenDatePicker(true);
    } else {
      setFilter(parseInt(value));
    }
  };
  const handleDateApply = () => {
    setOpenDatePicker(false)
  }
  const handleCancel = () => {
    setOpenDatePicker(false)
  }
  return (
    <div className={styles.container}>
      <div>
        {/* <ThotAI /> */}
      </div>
      <div className={styles.top}>
        <div className={styles.breadcrumb}>
          <span className={styles.non_active_page}>Home</span>
          <span>/</span>
          <span>Dashboard</span>
        </div>
        <div>
          <Select
            options={filterOptions}
            value={filter}
            onChange={handleFilter}
            setOpenDatePicker={setOpenDatePicker}
          />
        </div>
        <Dialog open={openDatePicker} onClose={() => setOpenDatePicker(false)} >
          <DialogTitle>Select Date</DialogTitle>
          <DialogContent>
            <DatePickerComponent />
          </DialogContent>
          <DialogActions>
            <DialogActions>
              <Button onClick={handleCancel} text="Cancel" backgroundColor="#E0E6ED" shadow='none' color="black" border="none"></Button>
              <Button onClick={handleDateApply} text="Apply" shadow='none' />
            </DialogActions>
          </DialogActions>
        </Dialog>
      </div>
      <div className={styles.total_send_card}>
        <div className={styles.title}>Total Spend</div>
        <div className={styles.left_container}>
          <div className={styles.values_container}>
            <div className={styles.amount}> $ 41,741.42</div>
            <div className={styles.spent_ratio}>+2.23%</div>
          </div>
          {/* <div className={styles.pattern}></div> */}
        </div>
      </div>
      <SectionHeader title="Funnel Stats" subHeading="Manage your team members and their account permissions here." />
      {/* stats card */}
      <div className={styles.cards_wrapper}>
        {funnel_stats1?.map((singleCard) => (
          <Card
            title={singleCard.title}
            amount={singleCard.amount}
            ratio={singleCard.ratio}
            lastWeekStats={singleCard.lastWeekStats}
            cardBGStyle={singleCard.cardBGStyle}
            chipBGStyle={singleCard.chipBGStyle}
          />
        ))}
      </div>

      <div className={styles.cards_wrapper}>
        {funnel_stats2?.map((singleCard) => (
          <Card
            title={singleCard.title}
            amount={singleCard.amount}
            ratio={singleCard.ratio}
            lastWeekStats={singleCard.lastWeekStats}
            cardBGStyle={singleCard.cardBGStyle}
            chipBGStyle={singleCard.chipBGStyle}
          />
        ))}
      </div>

      <div className={styles.cards_wrapper}>
        {funnel_stats3?.map((singleCard) => (
          <Card
            title={singleCard.title}
            amount={singleCard.amount}
            ratio={singleCard.ratio}
            lastWeekStats={singleCard.lastWeekStats}
            cardBGStyle={singleCard.cardBGStyle}
            chipBGStyle={singleCard.chipBGStyle}
          />
        ))}
      </div>

      <div className={styles.cards_wrapper}>
        {funnel_stats4?.map((singleCard) => (
          <Card
            title={singleCard.title}
            amount={singleCard.amount}
            ratio={singleCard.ratio}
            lastWeekStats={singleCard.lastWeekStats}
            cardBGStyle={singleCard.cardBGStyle}
            chipBGStyle={singleCard.chipBGStyle}
          />
        ))}
      </div>

      <SectionHeader title="Funnel Chart Stats" subHeading="Manage your team members and their account permissions here." />
      <div className={styles.two_chart_row}>
        <TwoChartRowCard
          title={"Cost Per Lead"}
          number={"32,324.3"}
          ratio={"- 12.3%"}
        />
        <TwoChartRowCard
          title={"Cost Per Call Booked"}
          number={"7,929"}

          ratio={"+0.25%"}
          loss={true}
        />
      </div>

      {/* 2 items line chart */}
      <div className={styles.total_spend_chart_container}>
        <div className={styles.card_top}>
          <div className={styles.heading}>Total Spend</div>
          <IconButton>
            <ThreeHorizentalDotsIcon color="black" />
          </IconButton>
        </div>
        <div className={styles.subHeading_container} style={{ flexWrap: isNearMobileScreen ? "wrap" : "nowrap", }}>
          <div className={styles.subHeading_wrapper}>
            <div className={styles.subHeading}>Total Profit</div>
            <div className={styles.amount}>$10,840</div>
          </div>
          <div className={styles.chart_labels}>
            <div className={styles.lebel_container}>
              <div className={styles.squre_box_red}></div>
              <div className={styles.text}>Total Spend</div>
            </div>
            <div className={styles.lebel_container}>
              <div className={styles.squre_box_green}></div>
              <div className={styles.text}>Cost Per Lead</div>
            </div>
          </div>
        </div>
        <div className={styles.chart}>
          <AdminProfitMultiLineChart />
        </div>
      </div>

      <SectionHeader title="Sales Funnel Chart Stats" subHeading="Manage your team members and their account permissions here." />
      {/* three charts row */}
      <div className={styles.three_chart_row_container}>
        {threeChartsData.slice(0, 3)?.map((item, index) => {
          return (
            <ThreeChartRowCard
              title={item.title}
              subTitle={item.subTitle}
              amount={item.amount}
              ratio={item.ratio}
              color={item.color}
              icon={item.icon}
              chart={<img src="/images/temp/chart.png" alt="chart temp img" />}
              loss={true}
            />
          );
        })}
      </div>
      <div className={styles.three_chart_row_container}>
        {threeChartsData.slice(3)?.map((item, index) => {
          return (
            <ThreeChartRowCard
              title={item.title}
              subTitle={item.subTitle}
              amount={item.amount}
              ratio={item.ratio}
              color={item.color}
              icon={item.icon}
              chart={<img src="/images/temp/chart.png" alt="chart temp img" />}
              loss={true}
            />
          );
        })}
      </div>


      {/* 3 items line chart */}
      <div className={styles.total_spend_chart_container} >
        <div className={styles.card_top}>
          <div className={styles.heading}>Sales Growth</div>
          <IconButton>
            <ThreeHorizentalDotsIcon color="black" />
          </IconButton>
        </div>
        <div className={styles.subHeading_container} style={{ flexWrap: isNearMobileScreen ? "wrap" : "nowrap", }}>
          <div className={styles.subHeading_wrapper}>
            <div className={styles.subHeading}>Total Profit</div>
            <div className={styles.amount}>$10,840</div>
          </div>
          <div className={styles.chart_labels}>
            <div className={styles.lebel_container}>
              <div className={styles.squre_box_blue}></div>
              <div className={styles.text}>Sales A</div>
            </div>
            <div className={styles.lebel_container}>
              <div className={styles.squre_box_green}></div>
              <div className={styles.text}>Sales B</div>
            </div>
            <div className={styles.lebel_container}>
              <div className={styles.squre_box_red}></div>
              <div className={styles.text}>Total Spend</div>
            </div>
          </div>
        </div>
        <div className={styles.chart}>
          <AdminGrowthMultiLineChart />
        </div>
      </div>
    </div>
  );
}
const Card = ({
  title,
  amount,
  ratio,
  lastWeekStats,
  chipBGStyle,
  cardBGStyle,
}) => {
  return (
    <div className={styles.card} style={cardBGStyle}>
      <div className={styles.card_top}>
        <div className={styles.text}>{title}</div>
        <IconButton>
          <ThreeHorizentalDotsIcon />
        </IconButton>
      </div>
      <div className={styles.card_body}>
        <div className={styles.text}>{amount}</div>
        <div className={styles.squre_box} style={chipBGStyle}>
          {ratio}
        </div>
      </div>
      <div className={styles.card_footer}>
        <IconButton>
          <EyeIcon />
        </IconButton>
        <div className={styles.text}>{lastWeekStats}</div>
      </div>
    </div>
  );
};

const TwoChartRowCard = ({ title, number, ratio, loss = false }) => {
  const color = loss ? "#E7515A" : "#00AB55";

  return (
    <div className={styles.two_chart_row_card}>
      <div className={styles.card_top}>
        <div className={styles.title}>{title}</div>
        <div className={styles.number}>{number}</div>
      </div>
      <div className={styles.card_body}>
        <AdminSmallLineChart color={color} />
      </div>
      <div className={styles.card_footer}>
        <div className={styles.ratio} style={{ color: color }}>
          {ratio}
        </div>
      </div>
    </div>
  );
};

const ThreeChartRowCard = ({
  title,
  subTitle,
  amount,
  ratio,
  color,
  icon,
  chart,
  loss = false,
}) => {
  // const color = loss ? "#E7515A" : "#00AB55";

  // Convert hexadecimal to RGB

  const rgbColor = hexToRgbConverter(color);
  const backgroundColor = `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.1)`;

  return (
    <div className={styles.three_charts_row_card}>
      <div className={styles.card_top}>
        <div
          className={styles.circle}
          style={{ backgroundColor: backgroundColor }}
        >
          <IconButton>{icon}</IconButton>
        </div>
        <div className={styles.title_wrapper}>
          <div className={styles.title}>{title}</div>
          <div className={styles.subTitle}>{subTitle}</div>
        </div>
      </div>
      <div className={styles.card_body}>
        <AdminSmallLineChart color={color} />
      </div>
      <div className={styles.card_footer}>
        <div className={styles.amount}>{amount}</div>
        <div className={styles.ratio} style={{ color: color }}>
          {ratio}
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
