import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/PageHeader";
import TableWrapper from "../../../components/TableWrapper";
import Pagination from "../../../components/Tables/Pagination";
import { extractPageData } from "../../../utils/extractPageData";
import Table from "../../../components/Tables";
import { jrSalesData } from "../../../assets/data/jrSalesData";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { getTableWidth } from "../../../utils/getTableWidth";
function JRPremiumSales() {
  const [tableData, setTableData] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [numberOfRecPerPage, setNumberOfRecordsPerPage] = useState(10);
  const [selectedColumns, setSelectedColumns] = useState([])
  const paginate = (pageNumber) => setCurrentPageNumber(pageNumber);
  const columnsText = [
    "Email",
    "UTM Source",
    "UTM Medium",
    "UTM Ad Name",
    "UTM Ad Set",
    "UTM Ad Name",
    "Campaign Name",
    "Country",
    "Registration Date",
    "Call Booked Date",
    "Amount",
    "PremiumURL"
  ]
  useEffect(() => {
    // Get current list/page data
    if (jrSalesData) {
      const data = extractPageData(
        jrSalesData,
        currentPageNumber,
        numberOfRecPerPage
      );
      setCurrentPageData(data);
    }
  }, [currentPageNumber, jrSalesData, numberOfRecPerPage]);
  useEffect(() => {
    setTableData(jrSalesData);
  }, []);
  const windowsDimensions = useWindowDimensions()
  return (
    <div>
      <PageHeader subHeading="Manage your team members and their account permissions here." />
      <TableWrapper
        // maxWidth={getTableWidth(windowsDimensions?.width, "jr")}
        columns={columnsText}
        setSelection={setSelectedColumns}
      >
        <Table
          data={currentPageData}
          columns={Object.keys(jrSalesData?.[0]).map((key, index) => ({
            name: key,
            text: selectedColumns[index] //columnsText[index]

          }))}
        />
        <Pagination
          totalData={tableData.length}
          dataPerPage={numberOfRecPerPage}
          paginate={paginate}
          currentPage={currentPageNumber}
          setNumberOfRecordsPerPage={setNumberOfRecordsPerPage}

        />
      </TableWrapper>
    </div>
  );
}

export default JRPremiumSales;
