import { generateRandomDate } from "./bestAdData";

export const todayReportData = Array.from({ length: 31 }, (_, index) => ({
    campaignId: Math.floor(Math.random() * 100000), // Random campaign ID
    campaignName: `Campaign ${index + 1} Name`, // Campaign name similar to data
    cost: parseFloat((Math.random() * 1000).toFixed(2)), // Random cost between 0 and 1000
    clicks: Math.floor(Math.random() * 1000), // Random clicks between 0 and 1000
    ctr: parseFloat((Math.random() * 10).toFixed(2)), // Random CTR between 0 and 10
    averageCpc: parseFloat((Math.random() * 5).toFixed(3)), // Random average CPC between 0 and 5
    leads: Math.floor(Math.random() * 10), // Random leads between 0 and 10
    costPerLead: parseFloat((Math.random() * 20).toFixed(2)), // Random cost per lead between 0 and 20
    optinRate: parseFloat((Math.random() * 100).toFixed(2)), // Random opt-in rate between 0 and 100
    hql: parseFloat((Math.random() * 10).toFixed(2)), // Random cost per lead between 0 and 10
    costPerHql: parseFloat((Math.random() * 10).toFixed(2)), // Random cost per lead between 0 and 10
    createdAt: generateRandomDate(), // Random date in the format dd/mm/yyyy

}));
