// uiSlice.js
import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    dateFilter: null,
    dateFilterOptionValue: null,
    sortFilter: null,
    searchText: null,
    isTopMenuBarOpen: false,
  },
  reducers: {
    setDateFilterState: (state, action) => {
      state.dateFilter = action.payload;
    },
    setDateFilterOptionValue: (state, action) => {
      state.dateFilterOptionValue = action.payload;
    },
    setSortFilterState: (state, action) => {
      state.sortFilter = action.payload;
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    setIsTopMenuBarOpen: (state, action) => {
      state.isTopMenuBarOpen = action.payload
    },
  },
});

export const {
  setDateFilterState,
  setDateFilterOptionValue,
  setSortFilterState,
  setSearchText,
  setIsTopMenuBarOpen,
} = uiSlice.actions;

export const selectSearchText = (state) => state.ui.searchText;
export const selectDateFilterState = (state) => state.ui.dateFilter;
export const selectSortFilterState = (state) => state.ui.sortFilter;
export const selectDateFilterOptionValue = (state) => state.ui.dateFilterOptionValue;
export const selectIsTopMenuBarOpen = (state) => state.ui.isTopMenuBarOpen;
export default uiSlice.reducer;
