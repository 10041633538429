import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/PageHeader";
import TableWrapper from "../../../components/TableWrapper";
import Pagination from "../../../components/Tables/Pagination";
import { extractPageData } from "../../../utils/extractPageData";
import Table from "../../../components/Tables";
import { crm_all_data } from "../../../assets/data/crm_all_data";
import { useSelector } from "react-redux";
import { selectDateFilterState } from "../../../redux/slice/ui/ui-slice";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { getTableWidth } from "../../../utils/getTableWidth";
function AllCallsBlocked() {
  const [tableData, setTableData] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [numberOfRecPerPage,setNumberOfRecordsPerPage] = useState(10);
  const dateFilter = useSelector(selectDateFilterState)
  const [selectedColumns, setSelectedColumns] = useState([])
  const paginate = (pageNumber) => setCurrentPageNumber(pageNumber);
  const columnsText = ["Email", "Date", "Utm_Source", "Utm_Medium", "Utm_Campaign", "Utm_Ad Set ID", "Utm_Ad ID", "Campaign Name", "Reg Date"]
  useEffect(() => {
    // Get current list/page data
    if (crm_all_data) {
      const data = extractPageData(
        tableData,
        currentPageNumber,
        numberOfRecPerPage
      );
      setCurrentPageData(data);
    }
  }, [currentPageNumber, tableData,numberOfRecPerPage]);
  useEffect(() => {
    setTableData(crm_all_data);
  }, []);
  // useEffect(() => {
  //   if (dateFilter) {
  //     const filterDate = new Date(dateFilter)
  //     const filteredData = crm_all_data.filter(item => {
  //       const itemDate = new Date(item.date);
  //       return itemDate.getTime() === filterDate.getTime();
  //     });
  //     setTableData(filteredData)
  //   } else {
  //     setTableData(crm_all_data);
  //   }
  // }, [dateFilter])
  const windowsDimensions = useWindowDimensions()
  return (
    <div>
      <PageHeader subHeading="Manage your team members and their account permissions here." />
      <TableWrapper 
      // maxWidth="89%"
      // maxWidth={getTableWidth(windowsDimensions?.width, "crm all")}
      columns={columnsText}
      setSelection={setSelectedColumns}
      >
        <Table
          data={currentPageData}
          columns={Object.keys(crm_all_data?.[0]).map((key, index) => ({
            name: key,
            text: selectedColumns[index] //columnsText[index]

          }))}
        />
        <Pagination
          totalData={tableData.length}
          dataPerPage={numberOfRecPerPage}
          setNumberOfRecordsPerPage={setNumberOfRecordsPerPage}

          paginate={paginate}
          currentPage={currentPageNumber}
        />
      </TableWrapper>
    </div>
  );
}

export default AllCallsBlocked;
