import React from 'react'
import PageHeader from '../../../components/PageHeader'
import styles from './style.module.scss';
import { EditIcon } from '../../../assets/icons/edit-icon.js';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
function Profile() {
    const navigate = useNavigate();
    const user = {
        first_name: 'John',
        last_name: 'Doe',
        email: 'johndoe@gmail.com',
        phone: '123-456-7890',
        address: 'Daska, Punjab, Pakistan',
    }

    const handleEditProfile = () => {
        navigate('/dashboard/edit-profile')
    }
    return (
        <div>
            <PageHeader title="Account" subHeading="Manage your team members and their account permissions here." hideButton={true} />
            <div className={styles.container}>
                <div className={styles.card}>
                    <div className={styles.left_wrapper}>
                        <img src={'/images/user.png'} alt="User" />

                    </div>
                    <div className={styles.right_wrapper}>
                        <div className={styles.col_1}>
                            <div className={styles.property}>
                                <div className={styles.label}>First Name</div>
                                <div className={styles.value}>{user.first_name}</div>
                            </div>

                            <div className={styles.property}>
                                <div className={styles.label}>Email</div>
                                <div className={styles.value}>{user.email}</div>
                            </div>
                            <div className={styles.property}>
                                <div className={styles.label}>Email</div>
                                <div className={styles.value}>{user.email}</div>
                            </div>

                        </div>
                        <div className={styles.col_2}>
                            <div className={styles.property}>
                                <div className={styles.label}>Last Name</div>
                                <div className={styles.value}>{user.last_name}</div>
                            </div>
                            <div className={styles.property}>
                                <div className={styles.label}>Phone</div>
                                <div className={styles.value}>{user.phone}</div>
                            </div>
                        </div>
                        <div className={styles.col_3}>

                        </div>
                    </div>
                    <span >
                        <IconButton onClick={handleEditProfile}>
                            <EditIcon />
                        </IconButton>
                    </span>
                </div>
            </div>
        </div >
    )
}

export default Profile