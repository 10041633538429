// Function to generate a random date string in the format dd/mm/yyyy
function generateRandomDate() {
    const day = Math.floor(Math.random() * 28) + 1; // Random day between 1 and 28
    const month = Math.floor(Math.random() * 12) + 1; // Random month between 1 and 12
    const year = Math.floor(Math.random() * (2023 - 2000 + 1)) + 2000; // Random year between 2000 and 2023
    return `${day}/${month}/${year}`;
}

// Function to generate a random email
function generateRandomEmail() {
    const domain = ["gmail.com", "yahoo.com", "hotmail.com"];
    const usernameLength = Math.floor(Math.random() * (10 - 5 + 1)) + 5; // Random username length between 5 and 10
    let username = "";
    for (let i = 0; i < usernameLength; i++) {
        const randomCharCode = Math.floor(Math.random() * (122 - 97 + 1)) + 97; // Random lowercase ASCII code
        username += String.fromCharCode(randomCharCode);
    }
    const selectedDomain = domain[Math.floor(Math.random() * domain.length)];
    return `${username}@${selectedDomain}`;
}

// Generate random data based on the provided structure
export const crm_hql_data = Array.from({ length: 3 }, (_, index) => ({
    date: generateRandomDate(), // Random date in the format dd/mm/yyyy
    emailAddress: generateRandomEmail(), // Random email address
    utmSource: "GoogleAds",
    utmMedium: "PMAX",
    utmCampaign: Math.random().toString().slice(2, 20), // Random 18-digit number
    utmAdSetId: Math.random().toString(36).substr(2, 10), // Random 10-character alphanumeric string
    utmAdId: Math.random().toString(36).substr(2, 10), // Random 10-character alphanumeric string
    glid: "N/A", // Assuming GLID is missing data, set to empty string
    createdAt: generateRandomDate(), // Random date in the format dd/mm/yyyy

}));

