import React, { useState } from 'react'
import styles from './styles.module.scss'
import SelectFilter from '../Select'
import DatePickerComponent from '../DatePicker';
import Button from '../Button';
import { selectIsTopMenuBarOpen, setDateFilterOptionValue, setDateFilterState, setSortFilterState } from '../../redux/slice/ui/ui-slice';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Select from "../Select";
import Search from '../search';
import ColumnSelector from '../ColumnSelect';
import { DownloadIcon } from '../../assets/icons/download-icon';
import { useMediaQuery } from '@mui/material';
import { custome_breakpoint, mobile_breakpoint } from '../../utils/constants';

const options = [
    { label: "Sort by", value: "" },
    { label: "Date", value: 'date' },
    { label: "Cost", value: "cost" },
    { label: "Leads", value: 'leads' },
    { label: "Cost per lead", value: 'cost per lead' },
]
const filterOptions = [
    { label: "Select Date", value: 999 },
    { label: "Past week", value: 7 },
    { label: "This month", value: 30 },
    { label: "Past 3 months", value: 90 },
    { label: "Past 12 months", value: 356 },
    { label: "Custom date", value: "custom_date" },
    { label: "All time", value: 111 },
];
function TableWrapper({ children, label, maxWidth = "100%", columns, setSelection }) {
    const dispatch = useDispatch();
    const isSideBarOpen = useSelector(selectIsTopMenuBarOpen)
    const isNearMobileScreen = useMediaQuery(custome_breakpoint);
    const isMobile = useMediaQuery(mobile_breakpoint);

    const [sortFilter, setSortFilter] = useState(options[0].value);
    const handleSortFilter = (event) => {
        dispatch(setSortFilterState(event.target.value));
        setSortFilter(event.target.value);
    };

    const [dateFilter, setDateFilter] = useState(filterOptions[0].value);
    const [date, setDate] = useState("");
    const [openDatePicker, setOpenDatePicker] = useState(false);

    const handleFilter = (event) => {
        const value = event.target.value;
        if (value === "custom_date") {
            setOpenDatePicker(true);
        } else {
            dispatch(setDateFilterOptionValue(value));
            setDateFilter(parseInt(value));
        }
    };
    const handleDateApply = () => {

        setOpenDatePicker(false)
        dispatch(setDateFilterState(date));

    }
    const handleCancel = () => {
        setOpenDatePicker(false)
    }

    return (
        <div className={styles.container} style={{ maxWidth }}>
            <div className={styles.wrapper} >

                <div className={styles.table_filter}>
                    <Button
                        text='Export to CSV'
                        startIcon={<DownloadIcon />}
                        styles={{ width: isNearMobileScreen ? '100%' : "" }}
                    />
                    <div className={styles.label}>{label}</div>
                    <div className={styles.filters_container} style={{ width: isNearMobileScreen ? '100%' : "" }}>
                        <SelectFilter
                            style={{width: isNearMobileScreen ? (isMobile ? "100%" : '48.5%') : "",}}
                            value={sortFilter} options={options} onChange={handleSortFilter} transparent={true}
                        />
                        <ColumnSelector
                            style={{
                                width: isNearMobileScreen ? (isMobile ? "100%" : '48.5%') : "200px",
                            }}
                            columns={columns} setSelection={setSelection}
                        />
                        <Select
                            style={{
                                width: isNearMobileScreen ? (isMobile ? "100%" : '48.5%') : "",
                            }}
                            options={filterOptions}
                            value={dateFilter}
                            onChange={handleFilter}
                            setOpenDatePicker={setOpenDatePicker}
                        />
                        <Dialog open={openDatePicker} onClose={() => setOpenDatePicker(false)} >
                            <DialogTitle>Select Date</DialogTitle>
                            <DialogContent>
                                <DatePickerComponent value={date} setValue={setDate} />
                            </DialogContent>
                            <DialogActions>
                                <DialogActions>
                                    <Button onClick={handleCancel} text="Cancel" backgroundColor="#E0E6ED" shadow='none' color="black" border="none"></Button>
                                    <Button onClick={handleDateApply} text="Apply" shadow='none' />
                                </DialogActions>
                            </DialogActions>
                        </Dialog>

                        <Search
                            style={{
                                width: isNearMobileScreen ? (isMobile ? "100%" : '48.5%') : "",
                            }}
                        />
                    </div>

                </div>
                {children}
            </div>
        </div>
    )
}

export default TableWrapper