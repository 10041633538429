import React from 'react'
import { useLocation } from 'react-router'
import styles from './style.module.scss'
import Button from '../Button';
import { DownloadIcon } from '../../assets/icons/download-icon';
function PageHeader({ title = "", subHeading = '', hideButton=false}) {
    const { pathname } = useLocation()
    const segments = pathname.split('/');
    const lastSegment = segments[segments.length - 1];
    return (
        <div className={styles.header_container}>
            <div className={styles.left_area}>
                <div className={styles.heading}>{title || lastSegment.split('-').join(' ')}</div>
                <div className={styles.sub_heading}>{subHeading}</div>
            </div>
            {/* <div className={styles.right_area}>
                {!hideButton && <Button
                    text='Export to CSV'
                    startIcon={<DownloadIcon />}
                />}
            </div> */}
        </div>
    )
}

export default PageHeader