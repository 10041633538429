import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/PageHeader";
import TableWrapper from "../../../components/TableWrapper";
import { tempData } from "../../../assets/data/tempData";
import Pagination from "../../../components/Tables/Pagination";
import { extractPageData } from "../../../utils/extractPageData";
import Table from "../../../components/Tables";
import { yesterdayReportData } from "../../../assets/data/yesterdayReportData";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { getTableWidth } from "../../../utils/getTableWidth";
function YesterdayReport() {
  const [tableData, setTableData] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [numberOfRecPerPage, setNumberOfRecordsPerPage] = useState(10);
  const [selectedColumns, setSelectedColumns] = useState([])
  const paginate = (pageNumber) => setCurrentPageNumber(pageNumber);
  const columnsText = ["Compaign ID", "Compaign Name", "Cost", "Clicks", "CTR", "Average CPC", "Leads", "Cost per lead", "Optin Rate", "HQL", "Cost Per HQL"]
  useEffect(() => {
    // Get current list/page data
    if (yesterdayReportData) {
      const data = extractPageData(
        yesterdayReportData,
        currentPageNumber,
        numberOfRecPerPage
      );
      setCurrentPageData(data);
    }
  }, [currentPageNumber, yesterdayReportData, numberOfRecPerPage]);
  useEffect(() => {
    setTableData(yesterdayReportData);
  }, []);
  const windowsDimensions = useWindowDimensions()
  return (
    <div>
      <PageHeader subHeading="Manage your team members and their account permissions here." />
      <TableWrapper
        // maxWidth={getTableWidth(windowsDimensions?.width, "reports")}
        columns={columnsText}
        setSelection={setSelectedColumns}
      >
        <Table
          data={currentPageData}
          columns={Object.keys(yesterdayReportData?.[0]).map((key, index) => ({
            name: key,
            text: selectedColumns[index] //columnsText[index]

          }))}
        />
        <Pagination
          totalData={tableData.length}
          dataPerPage={numberOfRecPerPage}
          setNumberOfRecordsPerPage={setNumberOfRecordsPerPage}
          paginate={paginate}
          currentPage={currentPageNumber}
        />
      </TableWrapper>
    </div>
  );
}

export default YesterdayReport;
