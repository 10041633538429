import { combineReducers } from "redux";
import authReducer from "./slice/auth/auth-slice";
import uiReducer from "./slice/ui/ui-slice";

const rootReducer = combineReducers({
  auth: authReducer,
  ui: uiReducer
});

export default rootReducer;
