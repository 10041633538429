import React, { useState, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function ColumnSelector({ style, columns, setSelection }) {
    const [selectedColumns, setSelectedColumns] = useState(columns.map(column => ({
        name: column,
        checked: true
    })));

    // useEffect(() => {
    //     setSelectedColumns(columns.map(column => ({
    //         name: column,
    //         checked: true
    //     })));
    // }, [columns]);

    useEffect(() => {
        const selected = selectedColumns.filter(column => column.checked).map(column => column.name);
        setSelection(selected);
    }, [selectedColumns]);

    const handleChange = (event) => {
        const name = event.target.name;  // Name is correctly set as column name
        const isChecked = event.target.checked;  // Directly use checked property from the checkbox

        console.log("name, isChecked", name, isChecked)
        setSelectedColumns(prev =>
            prev.map(column =>
                column.name === name ? { ...column, checked: isChecked } : column
            )
        );
    };
    const customeStyle = {
        width: 200,
        ...style
    }
    return (
        <FormControl style={customeStyle} size="small">
            <InputLabel id="demo-select-small-label">Columns</InputLabel>
            <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                label="Columns"
                value={selectedColumns.filter(column => column.checked).map(column => column.name)}
                renderValue={selected => selected.join(', ')}
                MenuProps={MenuProps}
            >
                {selectedColumns.map((column, index) => (
                    <MenuItem key={column.name + index} value={column.name}>
                        <Checkbox
                            checked={column.checked}
                            onChange={handleChange}
                            name={column.name}
                            inputProps={{ 'aria-label': 'controlled' }}

                        />
                        <ListItemText primary={column.name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
