import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { hexToRgbConverter } from '../../utils/hexToRgbConverter';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    // pointRadius: 0,
    maintainAspectRatio: false,
    interaction: {
        mode: 'index',
        intersect: false,
    },
    plugins: {
        legend: {
            position: 'top',
            display: false,
            labels: {
                usePointStyle: false, // Use circles as the legend items' shape
                fillStyle: "red",
                borderWidth: 0, // Adjust the border width
                lineWidth: 0,
            },
        },

    },
    scales: {
        x: {
            ticks: {
                autoSkip: true,
                maxTicksLimit: 12,
            },
            grid: {
                display: false, // Hide vertical grid lines
            },
        },
        y: {
            // ticks: {
            //     min: 0,
            //     max: 100,
            //     stepSize: 20,
            // },
            grid: {
                display: false,
            },
        },
    },
};


const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const data = {
    labels,
    datasets: [
        {
            fill: true,
            label: 'Total Spend',
            data: [53, 49, 46, 44, 49, 46, 47, 43, 47, 42, 49, 45],
            // data: labels.map((index) => (Math.floor(Math.random() * 90) + 10)),
            borderColor: '#E7515A',
            tension: 0.8, // Controls the line curvature for the second dataset
            borderWidth: 2, // Adjust the border width
            pointRadius: 0, // Set pointRadius to 0 to remove data points
            // pointHoverRadius: 5, // Set pointHoverRadius to 0 to remove data points on hover
            backgroundColor: (context) => {
                const chart = context.chart;
                const { ctx, chartArea } = chart;
                const rgb = hexToRgbConverter("#E7515A")
                const rgbColor = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.2)`;

                if (!chartArea) {
                    // This case happens on initial chart load
                    return null;
                }
                const gradient = ctx.createLinearGradient(
                    chartArea.left,
                    chartArea.top,
                    chartArea.left,
                    chartArea.bottom
                );
                gradient.addColorStop(0, rgbColor); // Top color
                gradient.addColorStop(1, 'rgba(255, 255, 255, 0)'); // Bottom color (white or transparent)
                return gradient;
            },
        },
        {
            fill: false,
            label: 'Cost Per Lead',
            data: [50, 45, 44, 46, 48, 45, 49, 45, 39, 44, 41, 45],
            borderColor: '#00AB55',
            tension: 0.8, // Controls the line curvature for the second dataset
            borderWidth: 2, // Adjust the border width
            pointRadius: 0, // Set pointRadius to 0 to remove data points
            backgroundColor: (context) => {
                const chart = context.chart;
                const { ctx, chartArea } = chart;
                const rgb = hexToRgbConverter("#00AB55")
                const rgbColor = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.2)`;

                if (!chartArea) {
                    // This case happens on initial chart load
                    return null;
                }
                const gradient = ctx.createLinearGradient(
                    chartArea.left,
                    chartArea.top,
                    chartArea.left,
                    chartArea.bottom
                );
                gradient.addColorStop(0, rgbColor); // Top color
                gradient.addColorStop(1, 'rgba(255, 255, 255, 0)'); // Bottom color (white or transparent)
                return gradient;
            },
        },

    ],
};

export function AdminProfitMultiLineChart() {

    return <div style={{ width: "100%", height: '50%' }}>

        <Line options={options} data={data} />
    </div>
}
