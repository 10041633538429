import { forwardRef, useLayoutEffect } from "react";
import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./SideBar.module.scss";
import {
  ArrowIcon,
  ArrowRightIcon,
  BestAdsIcon,
  CRMIcon,
  CampaignsIcon,
  DashboardIcon,
  MessagesIcon,
  MinusIcon,
  ReportIcon,
  SalesIcon,
  SideBarIcon,
} from "../../../assets/icons";
import { Minus } from "../../../assets/icons/minus-icon";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import Hamburger from "hamburger-react";
import { selectIsTopMenuBarOpen, setIsTopMenuBarOpen } from "../../../redux/slice/ui/ui-slice";
const adminMenu = [
  {
    title: "Dashboard",
    href: "/dashboard",
    icon: <DashboardIcon />,
  },
  {
    title: "Reports",
    // href: "/dashboard/reports",
    icon: <ReportIcon />,
    subMenus: [
      {
        title: "Daily Report",
        href: "/dashboard/reports/daily-report",
        icon: <Minus />,
      },
      {
        title: "Today Report",
        href: "/dashboard/reports/today-report",
        icon: <Minus />,
      },
      {
        title: "Yesterday Report",
        href: "/dashboard/reports/yesterday-report",
        icon: <Minus />,
      },
    ],
  },
  {
    title: "Campaigns",
    href: "/dashboard/campaigns",
    icon: <CampaignsIcon />,
  },
  {
    title: "Best Ads",
    // href: "/dashboard/best-ads",
    icon: <BestAdsIcon />,
    subMenus: [
      {
        title: "Best Ad Set",
        href: "/dashboard/ads/best-ad-sets",
        icon: <Minus />,
      },
      {
        title: "Best Ads",
        href: "/dashboard/ads/best-ads",
        icon: <Minus />,
      },
    ],
  },
  {
    title: "CRM",
    // href: "/dashboard/crm",
    icon: <CRMIcon />,
    subMenus: [
      {
        title: "All Calls Booked",
        href: "/dashboard/crm/all-calls-booked",
        icon: <Minus />,
      },
      {
        title: "All Leads",
        href: "/dashboard/crm/all-leads",
        icon: <Minus />,
      },
      {
        title: "High Quality Leads",
        href: "/dashboard/crm/heigh-quality-leads",
        icon: <Minus />,
      },
    ],
  },
  {
    title: "Sales",
    // href: "/dashboard/sales",
    icon: <SalesIcon />,
    subMenus: [
      {
        title: "JR Sales",
        href: "/dashboard/sales/jr-sales",
        icon: <Minus />,
      },
      {
        title: "JR Premium Sales",
        href: "/dashboard/sales/jr-premium-sales",
        icon: <Minus />,
      },
    ],
  },
  {
    title: "AI",
    href: "/dashboard/ai-chat",
    icon: <MessagesIcon />,
  },
];

const MobileSideBar = forwardRef(({ showNav, setShowNav }, ref) => {
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const dispatch = useDispatch()
  const windowsDimensions = useWindowDimensions()
  const [isOpen, setOpen] = useState(true)
  const isSideBarOpen = useSelector(selectIsTopMenuBarOpen)

  const [openedMenu, setOpenedMenu] = useState("");
  const [route, setRoute] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setOpen(isSideBarOpen)
  }, [isSideBarOpen])
  useEffect(() => {
    if (!isOpen) {
      dispatch(setIsTopMenuBarOpen(false))
    }
  }, [isOpen]);
  useEffect(() => {
    setRoute(location.pathname);
  }, [window.location.pathname]);
  const handleSubMenuItemClick = (link) => {
    if (link.length > 1) {
      navigate(link);
      dispatch(setIsTopMenuBarOpen(false))
    }
  }
  const handleMenuItemClick = (link, title) => {
    console.log("===>>> Clicking Menu")
    console.log(link)
    console.log(title)
    if (link) {
      navigate(link);
      dispatch(setIsTopMenuBarOpen(false))
    } else if (title !== openedMenu) {
      setOpenedMenu(title);
      setSubMenuOpen(true);
    } else {
      setSubMenuOpen(!subMenuOpen);
    }
  };
  return (
    <div
      ref={ref}
      className={styles.container}
      style={{

        width: "260px",
        display: isSideBarOpen ? "block" : "none",
        position: "fixed",
        top: "0px",
        transition: "transform 0.3s ease-in-out 0.9s",
        transform: isSideBarOpen ? "translateX(0%)" : "",
        animationDelay: "3s",
        zIndex: 100,
        height: "100lvh",

      }}
    >
      <div className={styles.brand_logo_container}>
        <div className={styles.brand_logo}>
          <img
            src="/images/logo.svg"
            alt="company logo"
            style={{ width: "32px" }}
          />
          <div className={styles.logo_text}>VRISTO</div>
        </div>
        <div
          className={styles.menu_btn}

        >
          <Hamburger toggled={isOpen} toggle={setOpen} size={20} />
        </div>
      </div>
      {/* Side bar for admin */}
      {
        <div className={styles.side_menu_wrapper}>
          {adminMenu.map((item, index) => (
            <>
              <div
                key={index}
                className={`${styles.side_menu_item_container}  ${route == item?.href ||
                  item?.subMenus?.map((item) => item.href)?.includes(route)
                  ? styles.active_menu_item
                  : ""
                  }`}
                onClick={() => handleMenuItemClick(item.href, item.title)}
              >
                <div style={{ width: "100%" }}>
                  <div className={`${styles.side_menu_item}`}>
                    {item.icon}
                    {isSideBarOpen && (
                      <div className={styles.menu_item_text}>{item.title}</div>
                    )}
                    {item.subMenus && (
                      <div
                        className={styles.arrow_icon}
                        style={{
                          transform:
                            item.title == openedMenu && subMenuOpen
                              ? "rotate(90deg)"
                              : "rotate(0deg)",
                        }}
                      >
                        <ArrowRightIcon />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {item.subMenus &&
                isSideBarOpen &&
                subMenuOpen &&
                item.title == openedMenu && (
                  <div className={styles.sub_menu_items_container}>
                    {item.subMenus.map((subMenuItem, index) => (
                      <div
                        onClick={() => handleSubMenuItemClick(subMenuItem.href ? subMenuItem.href : "")}
                        // to={subMenuItem.href ? subMenuItem.href : ""}
                        style={{
                          textDecoration: "none",
                          color: route == subMenuItem.href ? "#1570EF" : "",
                          cursor:"pointer"
                        }}
                        className={`${styles.sub_menu_item}`}
                        key={index}
                      >
                        <MinusIcon
                          color={
                            route == subMenuItem.href ? "#1570EF" : "#D6D6D6"
                          }
                        />
                        {subMenuItem.title}
                      </div>
                    ))}
                  </div>
                )}
            </>
          ))}
        </div>
      }
    </div>
  );
});

MobileSideBar.displayName = "MobileSideBar";

export default MobileSideBar;
