export function generateRandomDate() {
    const day = Math.floor(Math.random() * 28) + 1; // Random day between 1 and 28
    const month = Math.floor(Math.random() * 12) + 1; // Random month between 1 and 12
    const year = Math.floor(Math.random() * (2023 - 2000 + 1)) + 2000; // Random year between 2000 and 2023
    return `${month}/${day}/${year}`;
}

export const bestAdData = Array.from({ length: 19 }, (_, index) => ({
    adGroupName: `Ad Group ${index + 1}`, // Assuming ad group names follow a pattern
    adGroupId: Math.floor(Math.random() * 1000000000000), // Random ad group ID
    cost: parseFloat((Math.random() * 1000).toFixed(2)), // Random cost between 0 and 1000
    clicks: Math.floor(Math.random() * 1000), // Random clicks between 0 and 1000
    averageCpc: parseFloat((Math.random() * 10).toFixed(2)), // Random average CPC between 0 and 10
    ctr: parseFloat((Math.random() * 1).toFixed(2)), // Random CTR between 0 and 1
    leads: Math.floor(Math.random() * 10), // Random leads between 0 and 10
    costPerLead: parseFloat((Math.random() * 20).toFixed(2)), // Random cost per lead between 0 and 20
    optinRate: parseFloat((Math.random() * 100).toFixed(2)), // Random opt-in rate between 0 and 100
    callsBooked: Math.floor(Math.random() * 10), // Random calls booked between 0 and 10
    costPerCall: parseFloat((Math.random() * 20).toFixed(2)), // Random cost per call between 0 and 20
    psuSales: Math.floor(Math.random() * 10), // Random PSU sales between 0 and 10
    costPerSale: parseFloat((Math.random() * 20).toFixed(2)), // Random cost per sale between 0 and 20
    revenue: parseFloat((Math.random() * 1000).toFixed(2)), // Random revenue between 0 and 1000
    roi: parseFloat((Math.random() * 500).toFixed(2)), // Random ROI between 0 and 500
    createdAt: generateRandomDate(), // Random date in the format dd/mm/yyyy
}));