import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { hexToRgbConverter } from '../../utils/hexToRgbConverter';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export const options = {
    responsive: true,
    // pointRadius: 0,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'top',
            display: false,
            labels: {
                usePointStyle: true, // Use circles as the legend items' shape
                fillStyle: "red",
                borderWidth: 0, // Adjust the border width
                lineWidth: 0,
            },
        },

    },
    scales: {
        x: {
            display: false, // Hide x-axis

            ticks: {
                autoSkip: true,
                maxTicksLimit: 12, // Set the maximum number of x-axis ticks
            },
            grid: {
                display: false, // Hide vertical grid lines
            },
        },
        y: {
            display: false, // Hide x-axis

            ticks: {
                min: 0, // Set the minimum value for the y-axis
                max: 50, // Set the maximum value for the y-axis
                stepSize: 5, // Set the desired gap (interval) between values on the y-axis
            },
            grid: {
                display: false, // Hide vertical grid lines
            },
        },
    },
};

const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'Jan', 'Feb', 'Mar', 'Apr', 'Mar', 'Apr'];




export default function AdminSmallLineChart({ color }) {

    const data = {
        labels,
        datasets: [
            {
                fill: true,
                label: 'New Blogs',
                data: [5, 15, 10, 20, 5, 15, 10, 20, 12, 18, 25],
                borderColor: color,
                tension: 0, // Controls the line curvature for the second dataset
                borderWidth: 2, // Adjust the border width
                pointRadius: 0, // Set pointRadius to 0 to remove data points
                // pointHoverRadius: 5, // Set pointHoverRadius to 0 to remove data points on hover
                backgroundColor: "#FFF"
            },

        ],
    };
    return <div style={{ width: "100%" }}>

        <Line options={options} data={data} />
    </div>
}
