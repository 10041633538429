import React from "react";
import RoutesContainer from "./router/index";
import './styles/global.scss'

function App() {
  return (
    <>
      <RoutesContainer />
    </>
  );
}

export default App;
