import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/PageHeader";
import TableWrapper from "../../../components/TableWrapper";
import Pagination from "../../../components/Tables/Pagination";
import { extractPageData } from "../../../utils/extractPageData";
import Table from "../../../components/Tables";
import { dailyReportData } from "../../../assets/data/dailyReportData";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
function DailyReport() {
  const [tableData, setTableData] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [numberOfRecPerPage, setNumberOfRecordsPerPage] = useState(10);
  const [selectedColumns, setSelectedColumns] = useState([])
  const paginate = (pageNumber) => setCurrentPageNumber(pageNumber);
  const columnsText = ["Date", "Cost", "Leads", "Cost Per Lead", "HQL", "Cost Per HQL"]
  useEffect(() => {
    // Get current list/page data
    if (dailyReportData) {
      const data = extractPageData(
        dailyReportData,
        currentPageNumber,
        numberOfRecPerPage
      );
      setCurrentPageData(data);
    }
  }, [currentPageNumber, dailyReportData, numberOfRecPerPage]);
  useEffect(() => {
    setTableData(dailyReportData);
  }, []);
  const windowsDimensions = useWindowDimensions()
  return (
    <div>
      <PageHeader subHeading="Manage your team members and their account permissions here." />
      <TableWrapper

        columns={columnsText}
        setSelection={setSelectedColumns}
      >
        <Table
          data={currentPageData}

          columns={Object.keys(dailyReportData?.[0]).map((key, index) => ({
            name: key,
            text: selectedColumns[index] //columnsText[index]

          }))}
        />
        <Pagination
          totalData={tableData.length}
          dataPerPage={numberOfRecPerPage}
          paginate={paginate}
          setNumberOfRecordsPerPage={setNumberOfRecordsPerPage}

          currentPage={currentPageNumber}
        />
      </TableWrapper>
    </div>
  );
}

export default DailyReport;
