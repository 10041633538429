import React from 'react'
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useDispatch } from 'react-redux';
import { setDateFilterState } from '../../redux/slice/ui/ui-slice';
import moment from 'moment';
function DatePickerComponent({ value = 0, setValue = () => { }, label }) {
    const dispatch = useDispatch()
    const [date, setDate] = React.useState(dayjs(value));
    const handleChange = (newValue) => {
        const date = moment(newValue._d);
        setDate(newValue);
        setValue(date.format('MM/DD/YYYY'));
        setDate(date.format('MM/DD/YYYY'))
        // dispatch(setDateFilterState(date.format('MM/DD/YYYY')));
    }

    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DemoContainer components={['DatePicker']}>
                    <DatePicker
                        label={label}
                        slotProps={{ textField: { size: 'small' } }}
                        // value={date}
                        onChange={handleChange}
                    />
                </DemoContainer>
            </LocalizationProvider>
        </div>
    )
}

export default DatePickerComponent