import { useEffect, useState } from "react";
import { ArrowRightIcon } from "../../assets/icons";
import { NextDoubleArrowIcon } from "../../assets/icons/next-double-arrow";
import { PreviousDoubleArrowIcon } from "../../assets/icons/previous-double-arrow-icon";
import Button from "../Button";
import SelectFilter from "../Select";
import styles from './pagination.module.scss'
import { useMediaQuery } from "@mui/material";
import { custome_breakpoint } from "../../utils/constants";


const Pagination = ({
    dataPerPage,
    totalData,
    currentPage,
    paginate,
    setNumberOfRecordsPerPage
}) => {
    const totalPages = Math.ceil(totalData / dataPerPage);
    const [options, setOptions] = useState([])
    const [entriesOption, setEntriesOption] = useState();
    const isNearMobileScreen = useMediaQuery(custome_breakpoint);

    const handleSortFilter = (event) => {
        setEntriesOption(parseInt(event.target.value));
        setNumberOfRecordsPerPage(parseInt(event.target.value))
    };

    useEffect(() => {
        setOptions(new Array(25).fill().map((i, index) => ({ label: index + 1, value: index + 1 })))
    }, [])

    useEffect(() => {
        if (options && options.length > 0) {
            setEntriesOption(options[9].value)
        }
    }, [options])
    // Function to determine the starting and ending page numbers to show
    const getPageRange = (currentPage) => {
        const visiblePages = 3; // Number of page numbers to show
        let startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
        let endPage = Math.min(totalPages, currentPage + Math.floor(visiblePages / 2));

        // Add "..." if needed
        if (startPage > 1) {
            startPage = startPage - 1; // Show "..." before the first page number
        }
        if (endPage < totalPages) {
            endPage = endPage + 1; // Show "..." after the last page number
        }

        return { startPage, endPage };
    };

    const { startPage, endPage } = getPageRange(currentPage);

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    const handleNext = () => {
        currentPage !== totalPages && paginate(currentPage + 1);
    };
    const handleJumpToEnd = () => {
        currentPage !== totalPages && paginate(totalPages);
    };

    const handlePrevious = () => {
        currentPage !== 1 && paginate(currentPage - 1);
    };
    const handleJumpToStart = () => {
        currentPage !== 1 && paginate(1);
    };
    const ctrBtnsStyle = {
        display: "flex",
        width: "36px",
        height: "36px",
        padding: "7.6px 13.8px 8.4px 13.8px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "9999px",
        border: "none",
        background: "#e0e6ed",
        color: "black",
    }
    return (
        <div className={styles.root}>
            <div className={styles.entries_selector} style={{ width: isNearMobileScreen ? "100%" : "", }}>
                <p style={{ width: '-webkit-fill-available', minWidth: "max-content" }}>Showing 1 to {entriesOption} of 25 entries</p>
                <SelectFilter
                    style={{ width: isNearMobileScreen ? "100%" : "", }}
                    value={entriesOption} options={options} onChange={handleSortFilter} />
            </div>
            <div className={styles.left_btns_container}
                style={{ justifyContent: isNearMobileScreen ? "center" : "", }}
            >
                {/* Previous */}
                <Button
                    styles={ctrBtnsStyle}
                    startIcon={<PreviousDoubleArrowIcon />}
                    onClick={handleJumpToStart}
                    disabled={currentPage === 1}
                />
                <Button
                    styles={ctrBtnsStyle}
                    startIcon={<div style={{ transform: "rotate(180deg)", display: "flex" }}><ArrowRightIcon /></div>}

                    onClick={handlePrevious}
                    disabled={currentPage === 1}
                />


                {/* Page Numbers */}
                <div className={styles.page_no_container}>
                    {startPage > 1 && (
                        <span>
                            <Button styles={ctrBtnsStyle} text="..." disabled />
                        </span>
                    )}
                    {pageNumbers?.slice(0, 3)?.map((number, index) => {
                        const numberStyle = {
                            ...ctrBtnsStyle,
                            background: number === currentPage ? "#4361EE" : "#E0E6ED",
                            backgroundColor: number === currentPage ? "#4361EE" : "#E0E6ED",
                            color: number === currentPage ? "white" : "#344054",
                        }
                        return <span key={index} >
                            <Button
                                defaultStyle={false}
                                styles={numberStyle}
                                backgroundColor={number === currentPage ? "#4361EE" : "#E0E6ED"}
                                color={number === currentPage ? "white" : "#344054"}

                                text={number}
                                onClick={() => paginate(number)}
                            />
                        </span>
                    })}
                    {/* Show "..." if needed */}

                    {endPage < totalPages && (
                        <span>
                            <Button styles={ctrBtnsStyle} text="..." disabled />
                        </span>
                    )}
                </div>

                {/* Next Button */}
                <Button
                    styles={ctrBtnsStyle}
                    startIcon={<div style={{ display: "flex" }}><ArrowRightIcon /></div>}
                    onClick={handleNext}
                    disabled={currentPage === totalPages}
                />
                <Button
                    styles={ctrBtnsStyle}
                    startIcon={<NextDoubleArrowIcon />}
                    onClick={handleJumpToEnd}
                    disabled={currentPage === totalPages}
                />
            </div>
        </div >
    );
};

export default Pagination;
