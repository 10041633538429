import * as React from "react";
import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import style from "./login-form.module.scss";
import AdIcon from "../../../assets/icons/svg/ad-icon.svg";
import mailIcon from "../../../assets/icons/svg/mail.svg";
import keyIcon from "../../../assets/icons/svg/key.svg";
import eyeIcon from "../../../assets/icons/svg/eye.svg";
import eyeOffIcon from "../../../assets/icons/svg/eye-off.svg";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate();

  const handleSignIn=()=>{

    navigate("/dashboard")
  }
  return (
    <Box sx={{ flexGrow: 1 }} className={style.container}>
      <Grid container>
        <Grid item xs={12}>
          <div className={style.head}>
            <img src={AdIcon} alt="icon" />
            <div className={style.text}>Google Ads</div>
          </div>
          <div className={style.content}>
            <div className={style.text}>Sign In</div>
            <div className={style.description}>
              Let’s get started and give you a brand new experience
            </div>

            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                console.log(values);
              }}
            >
              {({ errors, touched }) => (
                <Form>
                  <div className={style.fields}>
                    <div style={{ display: "flex" }}>
                      <img className={style.icon} src={mailIcon} alt="icon" />
                      <Field
                        className={`${style.field} ${
                          errors.email && touched.email ? style.error : ""
                        }`}
                        name="email"
                        placeholder="Email"
                        type="email"
                      />
                    </div>
                    <ErrorMessage
                      name="email"
                      component="div"
                      className={style.errorText}
                    />
                    <div style={{ display: "flex" }}>
                      <img className={style.icon} src={keyIcon} alt="icon" />
                      <Field
                        className={`${style.field} ${
                          errors.password && touched.password ? style.error : ""
                        }`}
                        name="password"
                        placeholder="Password"
                        type={showPassword ? "text" : "password"}
                      />
                      <img
                        src={showPassword ? eyeOffIcon : eyeIcon}
                        alt="toggle"
                        className={style.eyeIcon}
                        onClick={togglePasswordVisibility}
                      />
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className={style.errorText}
                    />
                  </div>
                  <div className={style.remember_me}>
                    <div className={style.remember}>
                      <Field
                        type="checkbox"
                        name="remember"
                        className={style.checkBox}
                      />
                      <div className={style.text}>Remember me</div>
                    </div>
                    <div className={style.forgot_password}>
                      Forgot password?
                    </div>
                  </div>
                  <button className={style.signInBtn} onClick={handleSignIn}>
                    Sign In
                  </button>
                  <div className={style.create_account}>
                    <div className={style.sign_up}>Don't have an account?</div>
                    <div
                      className={style.text}
                      onClick={() => navigate("/register")}
                    >
                      Create free account
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}
