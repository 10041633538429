import { Fragment, useEffect, useState } from "react";
import TopBar from "./TopBar";
import SideBar from "./SideBar";
import styles from './index.module.scss'
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { useSelector } from "react-redux";
import { selectIsTopMenuBarOpen } from "../../../redux/slice/ui/ui-slice";
import MobileSideBar from "./MobileSideBar";
export default function DashLayout({ children }) {
    const [showNav, setShowNav] = useState(false);
    const isSideBarOpen = useSelector(selectIsTopMenuBarOpen)
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
    const windowsDimensions = useWindowDimensions()
    
    useEffect(() => {
        setShowNav(isSideBarOpen)
    }, [isSideBarOpen])
    return (
        <div className={styles.layout_root}>

            {windowsDimensions.width >= 600 && <div style={{ width: showNav ? "260px" : "80px", marginRight: "33px" }}>
                <SideBar showNav={showNav} setShowNav={setShowNav} />
            </div>}
            {windowsDimensions.width <= 600 && isSideBarOpen &&
                <MobileSideBar showNav={showNav} setShowNav={setShowNav} />
            }

            <div className={styles.main_wrapper}>
                <TopBar showNav={showNav} setShowNav={setShowNav} user={user} />

                <main className={`${styles.main_container}`} style={{ paddingTop: windowsDimensions.width <= 600 ? "30px" : "0px" }}>
                    <div className={styles.content_wrapper}>{children}</div>
                </main>
            </div>
        </div>
    );
}