import React from 'react'
import styles from './style.module.scss'
function SectionHeader({ title = "", subHeading = '' }) {

    return (
        <div className={styles.header_container}>
            <div className={styles.left_area}>
                <div className={styles.heading}>{title}</div>
                <div className={styles.sub_heading}>{subHeading}</div>
            </div>
        </div>
    )
}

export default SectionHeader