// Function to generate a random email
function generateRandomEmail() {
    const domain = ["gmail.com", "yahoo.com", "hotmail.com"];
    const usernameLength = Math.floor(Math.random() * (10 - 5 + 1)) + 5; // Random username length between 5 and 10
    let username = "";
    for (let i = 0; i < usernameLength; i++) {
        const randomCharCode = Math.floor(Math.random() * (122 - 97 + 1)) + 97; // Random lowercase ASCII code
        username += String.fromCharCode(randomCharCode);
    }
    const selectedDomain = domain[Math.floor(Math.random() * domain.length)];
    return `${username}@${selectedDomain}`;
}

// Generate random data based on the provided structure
export const jrSalesData = Array.from({ length: 3 }, (_, index) => ({
    email: generateRandomEmail(),
    utmSource:"GoogleAds" , // Randomly assign GoogleAds 
    utmMedium: "PMAX" , // Randomly assign PMAX 
    utmCampaignId: Math.random().toString().slice(2, 20), // Random 20-digit number
    utmAdSetId: Math.floor(Math.random() * 1000000).toString() , // Randomly assign a number or date
    utmAdName: "", // Assuming missing data for UTM Ad Name
    campaignName: "", // Assuming missing data for Campaign Name
    country: "", // Assuming missing data for country
    registrationDate: generateRandomDate(), // Randomly assign null or current date
    callBookedDate: generateRandomDate(), // Randomly assign null or current date
    amount: Math.random() < 0.5 ? 20 : "null" , // Randomly assign 20 or null
    premiumUrl: "#REF!", // Assuming #REF! indicates an error, kept as is
    createdAt: generateRandomDate(), // Random date in the format dd/mm/yyyy

}));

function generateRandomDate() {
    const day = Math.floor(Math.random() * 28) + 1; // Random day between 1 and 28
    const month = Math.floor(Math.random() * 12) + 1; // Random month between 1 and 12
    const year = Math.floor(Math.random() * (2023 - 2000 + 1)) + 2000; // Random year between 2000 and 2023
    return `${day}/${month}/${year}`;
}

