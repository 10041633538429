import React from 'react';
import { useDropzone } from 'react-dropzone';
import styles from "./style.module.scss"
import Button from '../Button';

export const Dropzone = ({ title = 'Or drag and drop files' }) => {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

    return (
        <div className={styles.container}>
            <div {...getRootProps({ className: 'dropzone' })} className={styles.dropArea}>
                <input {...getInputProps()} />
                <Button shadow='none' text='Add File' backgroundColor='#ffffff' color='#1E5EFF' />
                <p style={{textAlign:"center"}}>{title}</p>
            </div>
        </div>
    );
}