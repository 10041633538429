export const DollarIcon = ({ color = '#1570EF' }) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <g clip-path="url(#clip0_236_35244)">
            <path d="M4.71436 11.6571C4.71436 13.2351 5.99354 14.5143 7.5715 14.5143H10.4286C12.0066 14.5143 13.2858 13.2351 13.2858 11.6571C13.2858 10.0792 12.0066 8.79999 10.4286 8.79999H7.5715C5.99354 8.79999 4.71436 7.52081 4.71436 5.94285C4.71436 4.36489 5.99354 3.08571 7.5715 3.08571H10.4286C12.0066 3.08571 13.2858 4.36489 13.2858 5.94285M9.00007 1.65714V15.9428"
                stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_236_35244">
                <rect width="17.1429" height="17.1429" fill="white" transform="translate(0.428589 0.228561)" />
            </clipPath>
        </defs>
    </svg>
}