import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/PageHeader";
import TableWrapper from "../../../components/TableWrapper";
import Pagination from "../../../components/Tables/Pagination";
import { extractPageData } from "../../../utils/extractPageData";
import Table from "../../../components/Tables";
import { compaignsData } from "../../../assets/data/campaignsData";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
function Campaigns() {
  const [tableData, setTableData] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [numberOfRecPerPage, setNumberOfRecordsPerPage] = useState(25);
  const [selectedColumns, setSelectedColumns] = useState([])
  const paginate = (pageNumber) => setCurrentPageNumber(pageNumber);

  const columnsText = ["Campaign Name", "Campaign ID", "Cost", "Clicks", "Average CPC", "CTR", "Leads", "HQL", "Cost per Lead", "Cost Per HQL", "Optin Rate", "ROI", "Call Booked", "Revenue", "Basic Product Sales", "% Basic Product Sales", "Cost per sale", "Cost per Call Booked"]
  useEffect(() => {
    // Get current list/page data
    if (compaignsData) {
      const data = extractPageData(
        compaignsData,
        currentPageNumber,
        numberOfRecPerPage
      );
      setCurrentPageData(data);
    }
  }, [currentPageNumber, compaignsData, numberOfRecPerPage]);
  useEffect(() => {
    setTableData(compaignsData);
  }, []);
  useEffect(() => {
    console.log("selectedColumns", selectedColumns)
  }, [selectedColumns])

  return (
    <div>
      <PageHeader subHeading="Manage your team members and their account permissions here." />
      <TableWrapper
        columns={columnsText}
        setSelection={setSelectedColumns}
      >
        <Table
          data={currentPageData}
          columns={Object.keys(compaignsData?.[0]).map((key, index) => ({
            name: key,
            text: selectedColumns[index] //columnsText[index]
          }))}

        />
        <Pagination
          totalData={tableData.length}
          dataPerPage={numberOfRecPerPage}
          setNumberOfRecordsPerPage={setNumberOfRecordsPerPage}
          paginate={paginate}
          currentPage={currentPageNumber}
        />
      </TableWrapper>
    </div>
  );
}

export default Campaigns;
